.payment-methods {
  width: 100%;
  background-color: #f7f8fb;
  padding: 12px;
  border-radius: 8px;
}
.form-change-payment-method-on-cart {
  .ant-radio-checked .ant-radio-inner {
    border: none;
    &::after {
      width: 15px;
      height: 15px;
      left: 0;
      top: 0;
      background-image: url(../../../../assets/icons/Checked.svg);
      background-position: 3px 3px;
      background-repeat: no-repeat;
    }
  }
  .ant-form-item {
    margin-bottom: 8px;
  }
  .title-content-select-payment-method {
    display: flex;
    width: 100%;
    .block-start {
      background-color: #9aa6ba;
      width: 5px;
      height: auto;
      margin-right: 12px;
    }
  }
}
