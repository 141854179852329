@import "../../../../../styles/mixins";

.product-container {
  font-size: 14px;
  height: 100%;
  .ant-row {
    margin-bottom: 0;
  }
  .icon-new-vat {
    color: #32bf46;
    svg {
      path {
        fill: #32bf46;
      }
    }
  }
  .header-filter {
    margin-bottom: 20px;
    display: flex;
    padding: 8px 15px;
    background: #e9eef5;
    border-radius: 8px;
    align-items: center;
    overflow-x: auto;
    .filter-tab {
      padding: 8px 15px;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s ease;
      white-space: nowrap;
      &:not(:last-child) {
        margin-right: 20px;
      }
      &:hover {
        background-color: white;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
      }
    }

    .filter-tab-actived {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
      background-color: white;
      color: #2246dc;
      font-weight: bold;
    }
  }

  .alert {
    margin-bottom: 20px;
  }

  .product-id {
    font-weight: 600;
  }

  .status-on {
    color: #00ab78;
  }

  .status-off {
    color: #5b6673;
  }

  .filter-box {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    background-color: #e9eef5;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    .search-input {
      width: 30%;
    }

    .button-group {
      margin-left: auto;
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .icon {
    &:hover {
      cursor: pointer;
    }
  }

  .linked-text {
    color: #2246dc;
    font-weight: 600;
  }

  .title-table {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-transform: uppercase;
  }

  .filter-icon {
    color: black;
  }
  .list-watch-history-price {
    .ant-form-item {
      margin-bottom: 10px;
    }
    .ant-table td,
    .ant-table th {
      // text-align: center !important;
      vertical-align: middle;
      .anticon svg {
        color: #2246dc;
      }
    }
    .ant-table td {
      &:nth-child(2),
      &:last-child {
        color: #2246dc;
      }
      &:nth-child(3) {
        text-align: justify !important;
      }
    }
    .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #f0f2f5;
      padding: 0;
      border: none;
    }
    .ant-select-selection-placeholder {
      color: red;
    }
    .ant-row {
      .tags-render {
        width: auto !important;
        .ant-select-selection-item {
          background-color: #e5e5e5;
          border: 1px solid #afafaf;
          color: #afafaf;
        }
      }
      .ant-select-selection-item {
        cursor: default !important;
      }
      .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        cursor: default !important;
      }
    }
  }
}

// ===============================Reponesive====================================

@include laptop() {
  .product-container {
    .filter-box {
      gap: 20px;
      .search-input {
        width: 45%;
      }
    }
  }
}

@include tablet_landscape() {
  .product-container {
    .filter-box {
      .search-input {
        width: 60%;
      }
      .button-group {
        margin-left: 0;
        width: 100%;
        justify-content: center;
      }
    }
  }
}

@include mobile_portrait() {
  .product-container {
    .filter-box {
      flex-direction: column;
      .search-input {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    .product-code {
      display: none;
    }
  }
}
