.tabs-card {
  .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab {
    background-color: #e7f0ff;
    border-radius: 8px;
    padding: 6px 16px;
    .title {
      font-size: 14px;
      .content-card {
        gap: 0px !important;
        min-width: 100px;
        // min-height: 44px;
        .ant-skeleton {
          .ant-skeleton-title {
            margin: 0;
          }
          ul {
            display: none;
          }
        }
      }
    }
  }
  .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab.ant-tabs-tab-active {
    border: 1px solid #2246dc;
    border-radius: 8px;
  }
  .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
  .ant-tabs-nav::before {
    display: none;
  }
}
