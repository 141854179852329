@import "../../../../styles/mixins";

$upload-width-pc: 100%;
$upload-height-pc: 100%;
$upload-width-tablet: 450px;
$upload-height-tablet: 135px;
$upload-width-mobile: 225px;
$upload-height-mobile: 68px;

.custom-upload-container {
  .ant-upload-list-picture-card-container {
    width: $upload-width-pc;
    height: $upload-height-pc;
    margin: 0;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: $upload-width-pc;
    // height: $upload-height-pc;
  }
  .ant-upload-picture-card-wrapper {
    // display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .ant-form-item-extra {
    text-align: center;
  }
}
