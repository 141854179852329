@import "../../../../styles/mixins";

.create-account-container {
  .form-content {
    display: grid;
    grid-template-columns: 25% auto;
    gap: 25px;
    border-radius: 8px;
    .avatar-upload {
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 60px 30px;
      border-radius: 16px;
      position: relative;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      .title {
        font-weight: 600;
      }
      .avatar {
        height: 144px;
        width: 144px;
        padding: 10px;
        border: 1px dashed rgba(145, 158, 171, 0.5);
        border-radius: 50%;
        img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .upload {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        cursor: pointer;
        .icon_upload {
          width: 144px;
          height: 144px;
          justify-content: center;
          color: #919eab;
          font-size: 12px;
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          top: -70px;
          left: 50%;
          transform: translate(-50%, -50%);
          svg {
            width: 20px;
            height: 18px;
            path {
              fill: #919eab;
            }
          }
        }
        p {
          text-align: center;
        }
        .title-rules {
          text-align: center;
          font-size: 13px;
          font-style: italic;
          color: #919eab;
        }
      }
    }

    .common-info {
      background-color: white;
      border-radius: 16px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      position: relative;
      .form-fields {
        padding: 24px;
        display: grid;
        grid-template-columns: calc((100% - 20px) / 3) calc((100% - 20px) / 3) auto;
        grid-column-gap: 20px;
        margin-bottom: 50px;
      }
      .ant-select-multiple.ant-select-lg .ant-select-selection-item {
        height: 25px;
        font-size: 13px;
        background-color: #e7f0ff;
        color: #2246dc;
        display: flex;
        justify-content: center;
        align-items: center;
        .ant-select-selection-item-remove {
          display: flex;
          align-items: center;
          .anticon-close {
            background: #2247dc;
            border-radius: 100px;
            width: 14px;
            height: 14px;
            line-height: 16px;
            svg {
              width: 8px;
              height: 8px;
              path {
                fill: #e7f0ff;
              }
            }
          }
        }
      }
      .button-group {
        position: absolute;
        bottom: 0px;
        right: 24px;
        display: flex;
        justify-content: flex-end;
        button {
          height: 40px;
        }
        .cancel-btn {
          margin-right: 15px;
        }
      }
    }
  }
}

// ===============================Reponesive====================================
@include tablet_portrait() {
  .create-account-container {
    .button-group {
      button {
        width: 120px;
        height: 40px;
      }
    }

    .form-content {
      grid-template-columns: auto;
      .common-info {
        grid-template-columns: auto;
      }
    }
  }
}
