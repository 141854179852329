.cart-list {
  .ant-tabs-nav {
    margin-bottom: 0;

    .ant-tabs-nav-wrap {
      background-color: #052987;
      height: 47px;
      align-items: flex-end;
      .ant-tabs-nav-list {
        margin-left: 8px;
        margin-top: 8px;

        .ant-tabs-tab {
          height: 40px;
          margin: 0;
          border: none;
          border-radius: 10px 10px 0px 0px;
          background-color: #052987;
          color: #ffffff;
          .ant-tabs-tab-remove {
            color: #ffffff;
          }
        }
        .ant-tabs-tab-active {
          background: #f7f8fb;
          .ant-tabs-tab-remove {
            color: #262f3b;
          }
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #262f3b;
        }
        .ant-tabs-nav-add {
          background-color: #052987;
          border: none;
          display: flex;
          align-items: center;
          height: 40px;
        }
      }
    }
    .ant-tabs-extra-content {
      height: 48px;
      background-color: #052987;
      display: flex;
      align-items: center;
      .button {
        display: flex !important;
        color: #ffffff;
      }
    }
  }
}

.ant-tabs-nav-operations {
  background-color: #052987;
  height: 48px;

  .ant-tabs-nav-more {
    color: #ffffff;
  }
  .ant-tabs-nav-add {
    background-color: #052987 !important;
    border: none !important;
    display: flex;
    align-items: center;
  }
}
