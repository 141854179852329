.progress-bar-sold-quantity {
  position: relative;
  .ant-progress-text {
    position: absolute;
    font-size: 9px;
    font-weight: bold;
    color: white;
    left: 50%;
    bottom: 4%;
    text-transform: uppercase;
    z-index: 1;
    transform: translate(-50%, -50%);
    width: auto !important;
    margin-left: 0 !important;
  }
}
