@import "../../../../styles/mixins";

$upload-width-pc: 100%;
$upload-height-pc: 100%;
$upload-width-tablet: 450px;
$upload-height-tablet: 135px;
$upload-width-mobile: 225px;
$upload-height-mobile: 68px;

.upload-field {
  min-width: $upload-width-mobile;
  min-height: $upload-height-mobile;
  width: $upload-width-pc;
  height: $upload-height-pc;
}
.upload-field:hover {
  background: 0 0;
}
.upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.upload-button:hover {
  border-color: #2246dc;
  cursor: pointer;
}
.upload-preview {
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  height: 100%;
  max-height: 200px;
}
.upload-actions {
  width: $upload-width-pc;
  height: $upload-height-pc;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
  background-color: #00000080;
  border-radius: 12px;
  .upload-actions-container {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .actions-img {
    z-index: 10;
    width: 16px;
    margin: 0 4px;
    color: #ffffffd9;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s;
  }
}
.upload-actions:hover {
  opacity: 1;
}
