$primary: #052987;
$warning: #ef9351;
$tag: #2246dc;
$error: #d2434d;
$borderRight: 1px dashed #9cadc4;
$white-grey: #f7f8fb;
$gray: #5b6673;

.create-serial {
  .title-card {
    font-size: 16px;
    font-weight: 600;
  }
  .sub-title-card {
    color: $primary;
  }
  .border-right {
    border-right: $borderRight;
  }
  .summary {
    color: $primary;
    font-weight: 600;
  }
  .lack {
    color: $error !important;
  }
}
