.custom-upload-catalog-product-brand {
  .ant-upload-list {
    margin-top: 10px;
    display: grid;
    column-gap: 14px;
    row-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "main main main main main main";
  }
  .ant-upload-select-picture-card {
    grid-area: main;
    width: 100%;
  }
  .ant-upload-list-picture-card::before {
    content: none;
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    flex-direction: column;
  }
}
