.css-select-status {
  .available {
    .ant-select-selector {
      border: 1px solid rgba(34, 70, 220, 1);
      background-color: rgba(231, 240, 255, 1);
      .ant-select-selection-item {
        color: rgba(34, 70, 220, 1);
      }
    }
    .ant-select-arrow {
      color: rgba(34, 70, 220, 1);
    }
  }
  .contact {
    .ant-select-selector {
      border: 1px solid rgba(239, 147, 81, 1);
      background-color: rgba(255, 244, 236, 1);
      .ant-select-selection-item {
        color: rgba(239, 147, 81, 1);
      }
    }
    .ant-select-arrow {
      color: rgba(239, 147, 81, 1);
    }
  }
  .unavailable {
    .ant-select-selector {
      border: 1px solid rgba(91, 102, 115, 1);
      background-color: rgba(216, 223, 234, 1);
      .ant-select-selection-item {
        color: rgba(91, 102, 115, 1);
      }
    }
    .ant-select-arrow {
      color: rgba(91, 102, 115, 1);
    }
  }
}
