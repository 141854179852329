.list-profile-container {
  .button-edit {
    font-weight: 600;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .card-avatar {
      .avatar {
        margin: 50px auto;
        padding: 8px;
        position: relative;
        width: fit-content;
        height: fit-content;
        border-radius: 100px;
        border: 1px dashed rgba(145, 158, 171, 0.5);
        img {
          border-radius: 100px;
          width: 130px;
          height: 130px;
        }
        .upload {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          opacity: 1;
          .icon_upload {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
      }
      .title-rules {
        font-size: 12px;
        text-align: center;
        color: #999;
        font-style: italic;
        display: block;
        margin-top: -20px;
      }
    }
    .ant-select-multiple.ant-select-lg .ant-select-selection-item {
      height: 25px;
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      .ant-select-selection-item-remove {
        display: flex;
        align-items: center;
      }
    }
    .ant-form-item {
      margin-bottom: 16px;
    }
    .card-avatar {
      .card-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
        .ant-col {
          margin-bottom: 5px;
        }
      }
    }

    .card-info-majob {
      background-color: #fff;
      border: 1px solid #eee;
      box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 5px 0px;
      border-radius: 16px;
      padding: 16px;
      width: 100%;
      .title {
        font-size: 18px;
        font-weight: 600;
      }
      .card-info {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 30px;
        .ant-col {
          margin-bottom: 5px;
        }
      }
    }
    .card-info-person {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-column-gap: 30px;
      .card-avatar {
        .title {
          font-size: 18px;
          font-weight: 600;
        }
        background-color: #fff;
        border: 1px solid #eee;
        box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 5px 0px;
        border-radius: 16px;
        padding: 16px;
      }
    }
  }
}
