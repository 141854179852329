.form-button-division-order {
  background-color: #f7f8fb;
  margin: 12px 0;
  padding: 18px 20px;
  border-radius: 12px;
  .text-title-method {
    font-size: 15px;
    font-weight: 600;
  }
  .text-highline {
    font-size: 15px;
    font-weight: 600;
    color: #052987;
    padding-left: 10px;
  }
  .form-content {
    margin-top: 8px;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px 0px;
    .ant-form-item {
      margin-bottom: 0px !important;
      .ant-radio-group {
        padding-left: 20px;
        margin-bottom: 0px !important;
        .ant-radio-wrapper {
          width: 100%;
          margin: 7px 0;
          span:first-child {
            width: 16px;
          }
          span:last-child {
            display: block;
            width: 100%;
            .method-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              .text-money {
                color: #2246dc;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    .form-select-pick-shipper {
      margin-top: -12px;
      padding: 0px 5px 0 20px;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    border: none;
    &::after {
      width: 16px;
      height: 16px;
      left: 0;
      top: 0;
      background-image: url(../../../../../../assets/icons/Checked.svg);
      background-position: 3px 3px;
      background-repeat: no-repeat;
    }
  }
}
