.custom-form-campaign {
  .label-input {
    .ant-form-item-label {
      padding: 0 !important;
    }
  }
  .ant-form-item-no-colon {
    width: 100%;
    .label-select {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .ant-row {
        margin: 0;
      }
    }
  }
}
