.custom-drawer {
  ::-webkit-scrollbar {
    display: flex;
  }
  .ant-drawer-header {
    .ant-drawer-close {
      padding: 0;
      margin: auto;
      height: 100%;
      right: var(--placement-drawer-close);
    }
  }
  .ant-drawer-body {
    padding: 12px 24px;
  }
  .header-sticky {
    position: sticky;
    z-index: 2;
    top: 0;
    margin-bottom: 16px;
    .ant-card-bordered {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
  }
  .header-drawer {
    .ant-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          margin: 0 !important;
        }
        .ant-skeleton-paragraph {
          margin: 0 !important;
        }
      }
    }
  }
}
