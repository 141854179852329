.page-header {
  margin-bottom: 20px;
  .page-title {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 10px;
  }
}

.bread-crumb {
  font-size: 14px;
  line-height: 20px;
  color: #5b6673;
}
