$tag: #2246dc;
$white-grey: #e7f0ff;

.body-popup-view-all-serial {
  margin: 10px 0;
}
.custom-tag {
  background: $white-grey;
  padding: 2px 10px;
  border-radius: 8px;
  color: $tag;
  border: none;
  margin-bottom: 6px;
  border: 1px solid $tag;
}
.inline {
  display: inline;
}
