.create-seller-container {
  display: block;
  overflow-y: auto;

  overflow-x: auto;

  .seller-code {
    color: #1953d8;
  }
}
