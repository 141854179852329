$white-grey: #f7f8fb;
$gray: #5b6673;

.custom-select-seller {
  background: $white-grey !important;
  padding: 8px 0;
  .header-select {
    display: flex;
    .text-header {
      padding-left: 12px;
      color: $gray !important;
      font-weight: bold !important;
    }
  }
}
