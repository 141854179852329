.button-split-order-purchase-order {
  font-size: 13px;
  .ant-table td,
  .ant-table th {
    font-size: 13px;
  }

  .form-split-order-purchase-order {
    margin: 15px 0;
    overflow-y: scroll;
    max-height: 70vh;
    .custom-card-ant .title {
      font-size: 14px;
    }
    .ant-card-head-title {
      display: flex;
      justify-content: center;
      font-size: 13px;
      font-weight: 600;

      .ant-steps {
        font-size: 13px;
        width: fit-content;
        min-width: 400px;
      }
      .ant-steps-item-title {
        font-size: 13px;

        &:after {
          height: 2px;
          background-color: #1953d8;
        }
      }

      .ant-steps-item-active {
        .ant-steps-item-icon {
          background: #1953d8;
        }
        .ant-steps-icon {
          color: #fff;
        }
        .ant-steps-item-title {
          .title {
            color: #1953d8;
          }
        }
      }
      .ant-steps-item-container {
        display: flex;
        align-items: center;

        .ant-steps-item-icon {
          width: 20px;
          height: 20px;
          font-size: 11px;
          line-height: 18px;
        }
      }
    }
    .content-steps-1 {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .title-type-table {
        color: #5b6673;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 10px;
        display: block;
      }
      .ant-table-summary {
        border-radius: 16px;
        td {
          background-color: #f7f8fb;
        }
      }
    }
    .form-bottom {
      padding: 0;
      width: 100%;
      .ant-form-item {
        margin-bottom: 0px;
        .ant-upload-list-picture-card-container {
          width: 98px;
          height: 98px;
        }
        .ant-upload-select-picture-card {
          width: 98px;
          height: 98px;
        }
      }
    }
  }
}
