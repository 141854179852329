.menu-layout-custom {
  overflow: hidden;
  border-radius: 8px;
  min-width: 250px;
  min-height: 100px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
  .menu-header {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #d8dfea;
    height: 40px;
    p {
      margin: 0;
    }
    .title {
      font-weight: 600;
      font-size: 16px;
      color: #5b6673;
    }
    .setting {
      font-weight: 600;
      font-size: 14px;
      color: #262f3b;
      cursor: pointer;
      .apply {
        margin-left: 10px;
        color: #2246dc;
      }
    }
  }
  .menu-content {
    padding: 10px;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    background-color: #f7f8fb;
    gap: 10px;
    height: calc(100% - 40px);
    .ant-form-item {
      margin-bottom: 0px;
    }
    .ant-tree-show-line .ant-tree-switcher {
      background-color: transparent;
    }
    .check-box-group {
      width: 100%;
      overflow: auto;
    }
  }
}
