@import "../../../../../../../styles/mixins";

.common-info {
  .common-info-product-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px !important;
    .image-carousels {
      width: 350px;
      .slick-prev,
      .slick-prev:hover {
        z-index: 2;
        color: black;
        font-size: 1rem;
      }

      .slick-next,
      .slick-next:hover {
        z-index: 2;
        color: black;
        font-size: 1rem;
      }
      .carousels-for {
        margin: auto;
        display: flex !important;
        justify-content: center !important;
        .image-carousel-for {
          width: 300px;
          height: 300px;
          border-radius: 20px;
          object-fit: cover;
          margin: auto;
          cursor: zoom-in;
          border: 1px solid rgb(228, 228, 235);
        }
      }
      .slider-nav {
        width: 80%;
        margin: auto;
        padding-top: 12px;
        .carousels-nav {
          width: 100%;
          height: 100%;
          padding: 0 10px;
          cursor: pointer;
          .image-carousel-nav {
            width: 50px;
            height: 50px;
            border-radius: 8px;
            object-fit: cover;
            margin: auto;
          }
        }
        .slick-active {
          img {
            border: 1px solid blue;
            padding: 2px;
          }
        }
      }
    }
    .block-middle {
      .block-info-1 {
        .block-space-1 {
          gap: 0 !important;
          .item-space-1 {
            width: 100% !important;
          }
        }
      }
    }
    .prod-info {
      width: calc(100% - 350px);
      display: flex;
      flex-direction: column;
      gap: 10px;
      .prod-title {
        font-size: 1.25rem;
        font-weight: 600;
        color: #2246dc;
        display: flex;
        align-items: center;
        .share-icon {
          color: #2246dc;
        }
      }
      .line-separator {
        height: 20px;
        border: 1px solid #000000;
      }
      .prod-subtitle-1,
      .prod-subtitle-2 {
        color: #5b6673;
      }
      .prod-subtitle-1 {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .subtitle-label {
        font-size: 0.85rem;
      }
      .subtitle-value {
        font-size: 0.95rem;
        font-weight: 700;
      }
      .prod-price-lv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .prod-price-lv-label {
          font-size: 0.85rem;
        }
        .prod-price-lv-value {
          font-size: 1rem;
          color: #2246dc;
          font-weight: 500;
        }
      }
      .prod-attributes {
        margin-top: 10px;
        .prod-att-item {
          display: grid;
          grid-template-columns: 2.5fr 7.5fr;
          margin-bottom: 15px;
          .prod-att-label {
            display: flex;
            align-items: center;
            font-size: 1rem;
            font-weight: 600;
          }
          .tag-group {
            display: flex;
            align-items: center;
            gap: 20px;
            .att-value-tag {
              min-width: 75px;
              min-height: 50px;
              padding: 10px;
              border: 1px solid #2246dc;
              color: #2246dc;
              background-color: #ffffff;
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 600;
            }
            .highlight {
              color: #ffffff;
              background-color: #2246dc;
            }
            .ant-input-group-addon {
              background-color: #ffffff;
            }
            .att-value-tag:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
    .parameter-info {
      width: 370px;
      min-width: 250px;

      .wrap-parameter-info {
        border: 1px solid rgba(243, 243, 247, 1);
        border-radius: 8px;
        padding: 16px;
        .parameters {
          margin-top: 16px;
          width: 100%;
          gap: 6px !important;
          .parameter-item {
            padding: 4px 8px;
            // font-size: 13px;
          }
          :nth-child(odd) {
            .parameter-item {
              background-color: #f0f0f0;
              border-radius: 6px;
            }
          }
        }
        .button {
          width: 100%;
          justify-content: center;
        }
      }
    }
    .cental-info {
    }
  }
}
