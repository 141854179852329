.custom-item-odd {
  .wrap-items-odd {
    margin-top: 16px;
    width: 100%;
    gap: 6px !important;
    .item-odd {
      padding: 4px 8px;
    }
    .hidden-item {
      display: none;
    }
    :nth-child(odd) {
      .item-odd {
        background-color: #f0f0f0;
        border-radius: 6px;
      }
    }
  }
}
