.custom-modal-title {
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0;
}
.warning-icon {
  color: #f2a900;
  margin-right: 0.35rem;
}
.cart-validations {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.validate-message {
  margin-bottom: 10px;
}
.modal-warning-icon {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
  margin-bottom: 1rem;
}
