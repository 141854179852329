.create-export-key {
  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-footer {
      display: flex;
      border-radius: 8px;
      padding: 8px 6px;
      justify-content: end;

      :global(.ant-card-body) {
        display: flex;
        padding: 0;
        gap: 16px;
      }

      .button {
        &-cancel {
          font-weight: 600;
          border-color: #1953d8;
          color: #1953d8;
        }
      }
    }
  }
}

.blue {
  color: #2246dc;
}

.red {
  color: #d2434d;
}

.green {
  color: #008960;
}

.pop-up {
  &-info {
    background-color: #f7f8fb;
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 20px;
  }

  &-action {
    margin-bottom: 20px;

    .input-search {
      border-radius: 8px;

      :global(.ant-input-group-addon) {
        background-color: white;
        cursor: pointer;
        border-right: none;
      }

      &:hover,
      &:focus-within {
        :global(.ant-input-group-addon) {
          border-left-color: #2246dc;
          border-top-color: #2246dc;
          border-bottom-color: #2246dc;
        }
      }

      &:focus-within {
        box-shadow: 0 0 0 2px rgba(25, 83, 216, 0.2);
      }

      input {
        border-left: none;

        &:hover,
        &:focus {
          border-left-color: #d9d9d9;
          box-shadow: none;
        }
      }
    }
  }

  &-key-license-account {
    .tag-account {
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #fff;
      font-size: 14px;

      .icon-close-outlined {
        padding-right: 5px;
        font-weight: 700;
        cursor: pointer;
      }

      .gmail {
        padding: 5px 11px 5px 0px;
        flex: 2;
        text-align: center;
        background-color: #fafafa;
        padding-left: 11px;
        border-left: 1px solid rgb(0 0 0 / 15%);
        border-right: 1px solid rgb(0 0 0 / 15%);
      }

      .password {
        color: #2246dc;
        text-align: center;
        flex: 1;
        padding: 5px 11px;
      }

      .show-password {
        cursor: pointer;
      }
    }
  }

  &-content-center {
    justify-content: center;
  }
}

.input-tag {
  background-color: #e7f0ff;
  border-color: #1953d8;

  &.input-tag-popup {
    margin-top: 10px;
    border: none;
    border-radius: 5px;
  }

  .icon-close-outlined {
    color: #1953d8;
    cursor: pointer;
  }

  :global(.button) {
    display: block;

    .icon-plus-circle-outlined svg {
      color: #1953d8;
      font-size: 12px;
    }
  }
}

.modal-cancel {
  :global(.message) {
    margin-top: 20px;
  }
}

.modal-submit {
  :global(.modal-content) {
    :global(.custom-icon) {
      font-size: 50px;
      color: #fab257;
      margin-bottom: 0;
    }
  }

  .title,
  .sub-title {
    text-align: center;
  }

  .title {
    font-weight: 700;
    .blue {
      color: #4077e6;
    }
  }

  .sub-title {
    color: #fab257;
  }
}

.pc-check-pass-account {
  margin-left: -22px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .title {
    font-weight: 600;
    border-bottom: 1px solid black;
  }
  .description {
    display: inline-block;
  }
}

.btn-popover-disabled {
  display: none;
}

.btn-popover-primary {
  border: 1px solid #1953d8;
  font-weight: 600;
  color: #1953d8;
}
