.split-step-second-purchase-order {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .title-type-table {
    color: #5b6673;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
  }
  .summary-total {
    color: #052987;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-right: 2px dashed #9cadc3;
    padding: 8px 16px 8px 0;
    padding-right: 24px !important;
    .summary-total-items {
      .count-money {
        text-align: end;
      }
    }
  }
  .device-split {
    height: 0px;
    background-color: #d8dfea;
    width: 100%;
  }
  .payment-info {
    padding-left: 24px !important;
    .ant-form-item {
      // margin-bottom: 0px;
    }
  }
}
