.form-buttons {
  height: 50px;
  // box-shadow: 0 -3px 10px 0px rgb(0 0 0 / 10%);
  border-radius: 8px;
  .form-buttons-container {
    padding: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
  }
  .secondary-button {
    min-width: 100px;
    color: #2246dc;
    border-color: #2246dc;
    background-color: #ffffff;
    font-weight: 700;
  }
  .primary-button {
    min-width: 100px;
    color: #ffffff;
    border-color: #2246dc;
    background-color: #2246dc;
    font-weight: 700;
  }
}
