.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7.5%;
  grid-template-areas: "button-group button-group";
  .button-group {
    grid-area: button-group;
    display: flex;
    justify-content: flex-end;
    gap: 1%;
    .buttons {
      margin: 0;
    }
  }
  .uploadButton {
    width: 103px;
    height: 103px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.tag {
  background: #e7f0ff;
  border-radius: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #2246dc;
  margin: 4px;
}
