.input-tag-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .input-tag-input {
    flex: 1;
    width: auto;
  }

  .input-tag-tags {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }

  .input-tag-tag,
  .input-value {
    margin-right: 4px;
    margin-bottom: 4px;
  }
}
