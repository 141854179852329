.container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .upload-image {
    .upload {
      display: flex;
      justify-content: center;
      .uploadButton {
        display: flex;
        justify-content: center;
      }
    }
    .list-images {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 8px;
      .logo {
        width: 105px;
        height: 105px;
        object-fit: cover;
      }
    }
  }
  .info-product {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "product-code product-name brand" "category warranty uom" "product-size  product-size product-weight" "editor editor editor";
    column-gap: 16px;
    .product-code {
      grid-area: product-code;
    }
    .product-name {
      grid-area: product-name;
    }
    .short-code {
      grid-area: short-code;
    }
    .brand {
      grid-area: brand;
    }
    .category {
      grid-area: category;
    }
    .editor {
      grid-area: editor;
    }
    .warranty {
      grid-area: warranty;
    }
    .uom {
      grid-area: uom;
    }
    .product-size {
      grid-area: product-size;
    }
    .product-weight {
      grid-area: product-weight;
    }
    .code-type-online {
      grid-area: code-type-online;
    }
  }
}
