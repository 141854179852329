@import "../../../../styles/mixins";

.filter-purchase-order-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  .search-input {
    display: flex;
    gap: 20px;
    .icon {
      margin-right: 16px;
    }
    .custom-input {
      height: fit-content;
      width: 500px;
    }
  }
  .btn-create {
    background-color: #2246dc;
    font-weight: 600;
    color: white;
    font-size: 14px;
  }
}

// ===============================Reponesive======================================

@include tablet_landscape() {
  .filter-purchase-order-container {
    .search-input {
      gap: 0;
      .custom-input {
        margin: 0 12px;
      }
    }
  }
}

@include mobile_portrait() {
  .filter-purchase-order-container {
    flex-wrap: wrap;
    .search-input {
      .hide {
        display: none;
      }
      .icon {
        margin-right: 0 !important;
      }
    }
    .btn-create {
      margin-top: 16px;
    }
  }
}
