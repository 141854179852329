.toolbar-recommend-import {
  .toolbar-box {
    display: flex;
    justify-content: space-between;
    .search-input {
      width: 400px;
    }
    .btn-create {
      background-color: #2246dc;
      font-weight: 600;
      color: white;
      font-size: 14px;
    }
  }
}
