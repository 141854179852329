.badge-status-dot-custom {
  .ant-badge-status-dot {
    width: 10px;
    height: 10px;
    &::after {
      display: var(--after-display);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid var(--border-color);
      border-radius: 50%;
      animation: antStatusAnimate 1.2s infinite ease-in-out;
      content: "";
    }
  }
}

@keyframes antStatusAnimate {
  0% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}
