.form-input-search-by-serial {
  .select-tag-search {
    // margin-bottom: 15px;
    width: 100%;
    .ant-select-selector {
      padding: 3px 5px;
      .ant-select-selection-item {
        // background-color: #e7f0ff;
        border: 1px solid #c9c9c9;
        // border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1px 8px;
        gap: 3px;
        height: auto !important;
      }
      // .ant-select-selection-item-remove {
      //   color: #2246dc;
      // }
    }
  }
}
