.modal-recommend-product-name {
  display: inline;
  .title {
    font-weight: bold;
    font-size: 16px;
  }
  .modal-children {
    .label-custom {
      display: flex;
    }
  }
}
.button-add-product {
  color: #2246dc;
  font-weight: 600;
  border-color: #2246dc;
}
.label-custom {
  display: flex;
  justify-content: space-between;
}
