.button-reject-check-info-purchase-order {
  font-size: 13px;
  .ant-table td,
  .ant-table th {
    font-size: 13px;
  }
  .ant-select-selection-item {
    background: #e6f7ff;
    border-color: #91d5ff;
    color: #096dd9;
    font-size: 12px;
  }
  .form-check-info-purchase-order {
    margin-bottom: 15px;
    overflow-y: scroll;
    max-height: 550px;
    .message-extra-reject {
      color: #ef9351;
      font-size: 13px;
      margin-bottom: 8px;
    }
    .ant-form-item-extra {
      font-size: 12px;
    }
    .form-bottom {
      padding: 0;
      width: 100%;
      .ant-form-item {
        margin-bottom: 0px;
        .ant-upload-list-picture-card-container {
          width: 98px;
          height: 98px;
        }
        .ant-upload-select-picture-card {
          width: 98px;
          height: 98px;
        }
      }
    }
  }
}
