.form-saved-document-purchase-order {
  width: 100%;
  .form-saved-document-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  .ant-modal-close {
    overflow: hidden;
    display: none;
  }
  .button-group-action-saved-document-purchase-order {
    display: flex;
    gap: 20px;
    .ant-btn {
      padding: 0 20px;
      height: 36px;
      border: 1.5px solid #1953d8;
      font-weight: 600;
    }
    .ant-btn-secondary {
      color: #1953d8;
    }
  }
}
