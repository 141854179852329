.modal-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  .delete-icon {
    height: 60px;
    width: 60px;
    // background-image: url("../../assets/images/delete-icon.PNG");
    background-size: 100% 100%;
  }
  .title,
  .custom-icon {
    margin-bottom: 12px;
  }
  .message {
    margin-bottom: 12px;
    text-align: center;
    .wrapper-reason {
      .title {
        font-weight: 600;
        font-size: 16px;
        padding-bottom: 15px;
      }
      .sub-tit-warn {
        color: #ff7f00;
      }
    }
  }

  .modal-children {
    width: 100%;
    margin-bottom: 12px;
  }

  .btn-group {
    display: flex;
    gap: 20px;
    // margin-top: 20px;
    button {
      min-width: 100px;
      margin-right: 0px !important;
    }
    not-button {
      min-width: auto;
    }
  }
}

.button {
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  width: fit-content;
  &:hover {
    color: #2246dc;
  }
  svg {
    margin-right: 5px;
    font-size: 16px;
  }
  span {
    // font-weight: 600;
  }
}
.class-custom {
  .tag-modal {
    color: #2246dc;
    background: #e7f0ff;
    font-weight: 600;
    font-size: 16px;
    border-radius: 8px;
    padding: 15px;
  }
  .input-search {
    padding-bottom: 15px;
  }
}
