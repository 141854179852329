.info-receiver-and-sender {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .info-address {
    padding: 12px;
    width: 80%;
    border: 0.5px solid #9cadc4;
    border-radius: 8px;
    div:nth-child(1) {
      display: flex;
      justify-content: space-between;
    }
  }
}
