@import "../../../../../../styles/mixins";

.agent-code {
  color: #1953d8;
  font-weight: bold;
}

.actions {
  svg {
    cursor: pointer;

    &:first-child {
      margin-right: 25px;
    }
  }
}

.wallet-status {
  color: #d2434d;
}

.seller-list {
  .filter-box {
    .filter-btn {
      min-width: 230px;

      svg {
        margin-right: 10px;
      }
    }
  }
}

// ===============================Reponesive====================================
@include mobile_portrait() {
  .seller-list {
    .filter-box {
      .filter-btn {
        width: 100%;
      }
    }
  }
}
