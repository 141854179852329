.filter-layout {
  border-radius: 8px;
  min-width: 300px;
  min-height: 100px;
  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #d8dfea;
    height: 40px;
    p {
      margin: 0;
    }
    .title {
      font-weight: 600;
      font-size: 16px;
      color: #5b6673;
    }
    .setting {
      font-weight: 600;
      font-size: 14px;
      color: #262f3b;
      cursor: pointer;
      .apply {
        margin-left: 10px;
        color: #2246dc;
      }
    }
  }
  .filter-content {
    padding: 10px 15px;
    border-radius: 0 0 8px 8px;
    display: flex;
    flex-direction: column;
    background-color: #f7f8fb;
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
}
