@import "../../../../../styles/mixins";

.product-container {
  font-size: 14px;
  height: 100%;
  .ant-row {
    margin-bottom: 0;
  }
  .header-filter {
    margin-bottom: 20px;
    display: flex;
    padding: 8px 15px;
    background: #e9eef5;
    border-radius: 8px;
    align-items: center;
    overflow-x: auto;
    .filter-tab {
      padding: 8px 15px;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s ease;
      white-space: nowrap;
      &:not(:last-child) {
        margin-right: 20px;
      }
      &:hover {
        background-color: white;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
      }
    }

    .filter-tab-actived {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
      background-color: white;
      color: #2246dc;
      font-weight: bold;
    }
  }

  .alert {
    margin-bottom: 20px;
  }

  .product-id {
    font-weight: 600;
  }

  .status-on {
    color: #00ab78;
  }

  .status-off {
    color: #5b6673;
  }

  .filter-box {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    background-color: #e9eef5;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    .search-input {
      width: 30%;
    }

    .button-group {
      margin-left: auto;
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .icon {
    &:hover {
      cursor: pointer;
    }
  }

  .linked-text {
    color: #2246dc;
    font-weight: 600;
  }

  .title-table {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-transform: uppercase;
  }
  .button-product-price-logs {
    color: #2246dc;
    font-weight: 600;
    border-color: #2246dc;
  }
  .filter-icon {
    color: black;
  }

  .list-tag-stock-product {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    justify-content: end;
    .custom-tag-stock-product-available-ha {
      background-color: #f6ffed;
      border: 1px solid #52c41a;
      color: #52c41a;
      padding: 4px 10px 4px 10px;
      text-align: center;
      border-radius: 100px;
      .ant-badge-status-text {
        font-size: 12px !important;
        line-height: 1;
      }
    }

    .custom-tag-stock-product-available-ncc {
      background-color: #fff4ec;
      border: 1px solid #ff9914;
      color: #ff9914;
      padding: 4px 10px 4px 10px;
      text-align: center;
      border-radius: 100px;
      .ant-badge-status-text {
        font-size: 12px !important;
      }
    }

    .custom-tag-stock-product-out-of-stock {
      background-color: #feefef;
      border: 1px solid #d2434d;
      color: #d2434d;
      padding: 4px 10px 4px 10px;
      text-align: center;
      border-radius: 100px;
      .ant-badge-status-text {
        font-size: 12px !important;
      }
    }
  }
}

// ===============================Reponesive====================================

@include laptop() {
  .product-container {
    .filter-box {
      gap: 20px;
      .search-input {
        width: 45%;
      }
    }
  }
}

@include tablet_landscape() {
  .product-container {
    .filter-box {
      .search-input {
        width: 60%;
      }
      .button-group {
        margin-left: 0;
        width: 100%;
        justify-content: center;
      }
    }
  }
}

@include mobile_portrait() {
  .product-container {
    .filter-box {
      flex-direction: column;
      .search-input {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    .product-code {
      display: none;
    }
  }
}
