.serial-export-detail-page {
  .ant-typography {
    font-size: 13px;
  }
  .page-title {
    font-size: 20px;
  }
  .ant-card-head {
    font-size: 14px !important;
    min-height: auto;
    padding: 12px 25px;
  }
  .col-export-serial-header {
    .ant-card-head {
      background-color: #e7f0ff;
      font-weight: 600;
      font-size: 14px;
      min-height: auto;
      padding: 12px 25px;
    }
    .ant-card-head-title {
      padding: 0;
      .ant-typography {
        color: #262f3b;
      }
    }
    .subtitle-heading {
      .ant-typography {
        color: #052987;
        font-size: 12px;
        font-weight: 600;
      }
    }
    .Serial_title-card__ChtCs {
      color: #052987;
      font-size: 12px;
      font-weight: 600;
    }
    .form-control-radio-group {
      margin-bottom: 8px;
      padding-left: 18px;
      .ant-form-item-control {
        margin-left: 18px;
      }
      .ant-typography {
        color: #052987;
        font-size: 13px;
        font-weight: 600;
      }
      .ant-form-item {
        span {
          font-size: 13px;
        }
        margin-bottom: 0px;
      }
      .ant-form-item input[type="radio"] {
        width: 12px;
        height: 12px;
      }
    }
    .ant-radio-checked .ant-radio-inner {
      border: none;
      &::after {
        width: 15px;
        height: 15px;
        left: 0;
        top: 0;
        background-image: url(../../../../assets/icons/Checked.svg);
        background-position: 3px 3px;
        background-repeat: no-repeat;
      }
    }
    .form-order-info {
      border-right: 1px dashed #9cadc4;
      padding-right: 30px !important;
    }
    .form-add-info {
      padding-left: 30px !important;
    }
    .card-children {
      .ant-card-head {
        background-color: #fff;
        padding: 10px 12px;
      }
      .ant-card-body {
        font-size: 13px !important;
      }
    }

    .card-container-info {
      display: flex;
      flex-direction: column;
    }

    .card-container-info > div {
      flex-grow: 1;
    }
  }
  .col-export-serial-footer {
    .ant-card-head {
      background-color: #e7f0ff;
      font-weight: 600;
      font-size: 12px;
      min-height: auto;
      padding: 10px 24px;
    }
    .ant-card-head-title {
      padding: 0;
      .ant-typography {
        color: #262f3b;
      }
    }
    .subtitle-heading {
      .ant-typography {
        color: #052987;
        font-size: 12px;
        font-weight: 600;
      }
    }
    .form-control-radio-group {
      .ant-form-item-control {
        margin-left: 18px;
      }
    }
    .ant-radio-checked .ant-radio-inner {
      border: none;
      &::after {
        width: 15px;
        height: 15px;
        left: 0;
        top: 0;
        background-image: url(../../../../assets/icons/Checked.svg);
        background-position: 3px 3px;
        background-repeat: no-repeat;
      }
    }
    .form-order-info {
      border-right: 1px dashed #9cadc4;
      padding-right: 30px !important;
    }
    .form-add-info {
      padding-left: 30px !important;
    }
  }
  .CustomAffix_space__t5oLp {
    padding: 10px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .footer-table-seller {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    color: #1953d8;
    font-weight: 600;
    .button-add-row {
      background-color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 12px;
      width: 120px;
      justify-content: center;
      padding: 5px 0px;
      border-radius: 8px;
      border: 2px solid #1953d8;
      gap: 5px;
    }
  }
  .select-serial {
    width: 90px;
  }
  .ant-space-align-center {
    width: 100%;
    justify-content: space-between;
  }
  .input-tag-container {
    .ant-space {
      width: 100%;
      justify-content: space-between;
    }
  }

  .ant-card-body {
    padding: 16px;
  }
  .input-tag-tags-detail {
    display: flex;
    flex-wrap: wrap;
    .ant-tag {
      border: 1px solid #1953d8;
      height: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e7f0ff;
      color: #1953d8;
      width: auto;
      font-size: 12px;
      gap: 5px;
      width: fit-content !important;
      margin-bottom: 5px;
      svg {
        fill: #1953d8;
      }
    }
  }

  .site-tag-plus {
    width: fit-content !important;
  }
  .form-footer {
    display: flex;
    background: #fff;
    border-radius: 8px;
    padding: 8px 6px;
    justify-content: end;
    .ant-card-body {
      display: flex;
      padding: 0;
      gap: 16px;
    }
  }
  .title-sub-title-card {
    color: #052987;
    font-size: 13px;
    font-weight: 600;
  }
  .form-order-select {
    .ant-form-item-label {
      width: calc(100% / 24 * 5 + 8px);
      text-align: left;
    }
  }
  .form-seller-select {
    .ant-form-item-label {
      width: calc(100% / 24 * 5 + 8px);
      text-align: left;
    }
  }
  .custom-table-seller {
    .ant-tag {
      border: 1px solid #1953d8;
      height: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e7f0ff;
      color: #1953d8;
      width: auto;
      font-size: 12px;
      gap: 5px;
      width: fit-content !important;
      svg {
        fill: #1953d8;
      }
      .ant-tag-close-icon {
        margin-left: 0;
      }
    }
  }
  .sumary-total {
    font-size: 14px;
    font-weight: 600;
    color: #052987;
    margin-top: 15px;
    .ant-row {
      margin-bottom: 10px;
      .ant-col:last-child {
        text-align: end;
      }
    }
  }
}
.form-model-list-tags-export-serial {
  .ant-typography {
    font-size: 13px;
  }
  .ant-spin {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .ant-checkbox-group {
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 5px;
    column-gap: 10px;
    text-align: center;
    .ant-checkbox-group-item {
      margin-right: 0;
      border: 1px solid #1953d8;
      padding: 3px 5px;
      display: flex;
      justify-content: center;
      border-radius: 8px;
      background-color: #e7f0ff;
      font-size: 13px;
      font-weight: 600;
      position: relative;
      margin-bottom: 5px;
      .ant-checkbox {
        display: none;
      }
      .ant-checkbox-checked {
        position: absolute;
        right: -7px;
        top: -7px;
        display: block;
        .ant-checkbox-inner {
          border-radius: 100px;
          background-color: green;
          border: 1px solid green;
          :hover {
            border: 1px solid green;
          }
        }
      }
    }
  }
  .ant-pagination {
    display: flex;
    li:first-child {
      margin-right: auto;
    }
  }
}
.modal-status-order-by-export-serial {
  .ant-modal-body {
    .modal-children {
      svg {
        width: 15px;
      }
    }
  }
}

.input-tag-tags-detail-serial {
  border: 1px solid #1953d8;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e7f0ff;
  color: #1953d8;
  width: auto;
  font-size: 12px;
  gap: 5px;
  width: fit-content !important;
  margin-bottom: 5px;
  svg {
    fill: #1953d8;
  }
}
