.custom-collapse {
  width: 100%;
  .collapses {
    background-color: white;
    .ant-collapse-header {
      padding: 10px;
    }
    .btn-delete {
      height: 24px;
      padding: 0px 15px;
      color: blue;
      border-color: blue;
      &:hover {
        color: white;
        background-color: blue;
        border-color: transparent;
      }
    }
    .panel-main {
      padding: 10px 20px !important;
      .title-main {
        font-style: italic;
        color: #5c5c5c;
      }
      .collapse-nested {
        background-color: white;
        margin: 10px 0;
        .col-left {
          flex-direction: row !important;
          gap: 20px;
          align-items: center;
          margin: 0 !important;
          .ant-form-item-label {
            padding: 0;
          }
          .form-flex {
            display: flex !important;
          }
          .character-required {
            &::before {
              display: inline-block;
              margin-right: 4px;
              color: #ff4d4f;
              font-size: 14px;
              font-family: SimSun, sans-serif;
              line-height: 1;
              content: "*";
            }
          }
          .ant-form-item {
            flex-direction: row !important;
            gap: 20px;
            align-items: center;
            margin: 0 !important;
          }
        }
        .wrapper-collapse-nested {
          padding: 20px;
          display: flex;
          justify-content: space-between;
          .input-number {
            width: 70px;
          }
          .condition-combo {
            .ant-form-item-row {
              flex-direction: row !important;
            }
            .form-flex {
              .ant-input-group {
                display: flex;
              }
              .character-required {
                &::before {
                  display: inline-block;
                  margin-right: 4px;
                  color: #ff4d4f;
                  font-size: 14px;
                  font-family: SimSun, sans-serif;
                  line-height: 1;
                  content: "*";
                }
              }
            }
            .ant-input-group-addon {
              width: 35px;
              position: relative;
              .icon-currency {
                color: white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }
      .ant-card-head {
        min-height: 0px;
        .ant-card-head-wrapper {
          .ant-card-head-title {
            padding: 8px 0;
            font-weight: bold;
          }
        }
      }
      .ant-card-body {
        .col-left {
          flex-direction: row !important;
          gap: 20px;
          align-items: center;
          margin: 0 !important;
          .ant-form-item-label {
            padding: 0;
          }
          .form-flex {
            display: flex !important;
          }
          .character-required {
            &::before {
              display: inline-block;
              margin-right: 4px;
              color: #ff4d4f;
              font-size: 14px;
              font-family: SimSun, sans-serif;
              line-height: 1;
              content: "*";
            }
          }
          .ant-form-item {
            flex-direction: row !important;
            gap: 20px;
            align-items: center;
            margin: 0 !important;
          }
        }
      }
    }
  }
}
