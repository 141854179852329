.skeleton-custom {
  .ant-skeleton-content {
    .ant-skeleton-title {
      margin: 0 !important;
      width: 100% !important;
    }
    .ant-skeleton-paragraph {
      margin: 0 !important;
      margin-top: 4px !important;
    }
  }
}
