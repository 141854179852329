.info-product-and-bill {
  display: flex;
  gap: 32px;
  .left {
    width: 65%;
    .custom-checkbox {
      margin: 0;
      .ant-form-item-control {
        height: 16px;
        .ant-form-item-control-input {
          min-height: 16px;
        }
      }
    }
    .products {
      .ant-space-item:nth-child(2) {
        margin-bottom: 24px;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 35%;
    .bill,
    .note {
      width: 100%;
      background-color: #f7f8fb;
      padding: 12px;
      border-radius: 8px;
    }
    .note {
      margin: 0 !important;
    }
  }
}
