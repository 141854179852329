.modal-import-price-series {
  display: inline;
  width: 409px !important;
  height: 340px !important;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .modal-children {
    margin-bottom: 10px;
    .label-custom {
      display: flex;
    }
    .ant-radio-checked .ant-radio-inner {
      border: none;
      &::after {
        width: 16px;
        height: 16px;
        left: 0;
        top: 0;
        background-image: url(../../../../../../../assets/icons/Checked.svg);
        background-position: 3px 3px;
        background-repeat: no-repeat;
      }
    }
  }
  .btn-group {
    .ant-btn {
      width: 120px;
      height: 40px;
      color: #fff;
      &:first-child {
        background-color: #fff;
        border: 1px solid #2246dc;
      }
      &:last-child {
        background-color: #2246dc;
      }
    }
  }
  .ant-upload.ant-upload-drag {
    border: 1px dashed #2246dc !important;
    p.ant-upload-drag-icon {
      margin-bottom: 0;
    }
    .ant-upload-text {
      font-weight: 400;
      font-size: 14px;
      color: #2246dc;
    }
  }
  .ant-typography {
    margin-top: 5px;
    display: block;
  }
  .text-info-file {
    text-align: center;
    font-size: 10px;
    color: #99a8ba;
  }
  .text-download-file {
    text-align: center;
    font-size: 14px;
  }
}
