.DESC path:first-child {
  fill: #1953d8 !important;
  stroke: #1953d8 !important;
}
.ASC path:last-child {
  fill: #1953d8 !important;
  stroke: #1953d8 !important;
}
.ant-table th {
  text-align: center !important;
  vertical-align: middle;
  font-weight: 600 !important;
  color: #5b6673 !important;
}
