.content-profit-reconciliation {
  padding-bottom: 24px;
  .card-block > div {
    flex-grow: 1;
  }
  .card-block {
    display: flex;
    flex-direction: column;
  }
  .full-events {
    &.fade-out {
      opacity: 0;
      display: none;
    }
    &.fade-in {
      display: block;
      opacity: 1;
    }
  }
}
