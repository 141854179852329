.render-content-modal-cash-approval-payment {
  display: flex;
  flex-direction: column;
  padding: 8px 16px 0 16px;
  font-size: 13px;
  gap: 8px;
  .list-checkbox {
    display: inline-flex;
    gap: 10px;
    align-items: center;
    .ant-checkbox-inner {
      border-radius: 5px;
    }
    .ant-typography {
      font-size: 13px;
    }
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
  .ant-upload-list-picture-card-container {
    width: 80px;
    height: 80px;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 80px;
    height: 80px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 80px;
    height: 80px;
  }
  .ant-form-item-extra {
    .ant-typography {
      font-size: 12px;
      color: #9cadc3;
    }
  }
}
