.upload-logo-product-brand {
  .ant-upload {
    width: 65px;
    height: 65px;
    background-color: transparent;
  }
  .ant-upload-list-picture-card-container {
    width: 65px;
    height: 65px;
    padding: 0;
  }
  .ant-upload-list-item {
    padding: 0;
  }
}
