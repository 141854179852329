.form-cancel-check-info-purchase-order {
  width: 100%;
  .ant-btn-secondary {
    color: #1953d8;
    border: 1.5px solid #1953d8;
    font-weight: 600;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-checkbox-group {
    padding: 0 0 16px 20px;
  }
  .form-confirm-deposit-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  .ant-modal-close {
    overflow: hidden;
    display: none;
  }
  .button-group-action-confirm-deposit-purchase-order {
    display: flex;
    gap: 20px;
    .ant-btn {
      width: 100px;
      height: 36px;
      border: 1.5px solid #1953d8;
      font-weight: 600;
    }
  }
}
