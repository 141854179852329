.custom-list-file-image {
  .ant-image-mask {
    font-size: 12px !important;
  }
  .ant-image {
    border-radius: 12px;
    overflow: hidden;
  }
  .list-image {
    overflow: hidden;
    width: fit-content;
    height: fit-content;

    .ant-image {
      position: relative;
      border-radius: 12px;
      overflow: hidden;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 1;
        pointer-events: none;
        color: #fff;
        font-size: 20px;
      }
    }

    .show-image {
      position: relative;

      .show-more {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        transform: translate(-50%, -50%);
        display: block;
      }
      &:hover .show-more {
        display: none;
      }
    }
  }
}
