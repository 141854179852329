.form-check-info-purchase-order {
  margin: 15px 0;
  font-size: 13px;
  .form-header {
    background-color: #f7f8fb;
    text-align: center;
    padding: 8px 10px;
    vertical-align: middle;
    border-radius: 8px;
    font-weight: 600;
    color: #5b6673;
    .ant-col {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .form-content {
    .ant-form-item {
      width: 100%;
      margin-bottom: 0px;
    }
    .form-content-item {
      width: 100%;
      padding: 12px 10px;
      border-bottom: 1px solid #cfcfcf;
      font-size: 13px !important;
      .ant-form-item-explain-error {
        font-size: 12px;
        min-height: 0px;
        color: #d2434d;
      }
      .form-item-radio {
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-radio-inner {
          border-radius: 4px;
        }
        .ant-radio-checked .ant-radio-inner {
          border: none;
          &::after {
            border-radius: 4px;
            width: 16px;
            height: 16px;
            left: 0;
            top: 0;
            background-image: url(../../../../../assets/icons/Checked.svg);
            background-position: 3px 3px;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
  .form-check-info-extra {
    color: #d2434d;
    font-size: 13px;
    margin-top: 8px;
    display: block;
  }
}
.button-group-action-check-info-purchase-order {
  display: flex;
  gap: 20px;
  .ant-btn {
    padding: 0 20px;
    height: 36px;
    border: 1.5px solid #1953d8;
    font-weight: 600;
  }
  .ant-btn-secondary {
    color: #1953d8;
  }
  .ant-btn-primary[disabled] {
    color: white;
    border-color: #1953d8;
    background: #1953d8;
    text-shadow: none;
    box-shadow: none;
    opacity: 0.6;
  }
}
