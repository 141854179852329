$white-grey: #f7f8fb;
$gray: #5b6673;

.custom-select-product-load-more {
  background: $white-grey !important;
  .header-select {
    color: $gray !important;
    font-weight: bold !important;
  }
}
