.notify-container {
  background-color: #ffffff;
  max-height: 100%;
  box-shadow:
    0 3px 6px -4px rgb(0 0 0 / 12%),
    0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  .header {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 0px 5px;
    border-bottom: 1px solid #d8dfea;
  }
  .content {
    padding: 16px;
    .search-input {
      margin-bottom: 16px;
    }
    .pagination {
      display: flex;
      align-items: center;
      justify-content: right;
      margin-top: 10px;
    }
  }
}
