.status-tabs {
  position: relative;
  margin-bottom: 20px;
  .left-arrow,
  .right-arrow {
    border: 1px solid #e0e0e0;
    position: absolute;
    top: calc(50% - 15px);
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
      border: 1px solid #1953d8;
      color: #1953d8;
    }
  }

  .left-arrow {
    left: -10px;
  }

  .right-arrow {
    right: -10px;
  }

  .status-list-container {
    background-color: #e9eef5;
    padding: 8px;
    margin-bottom: 20px;
    border-radius: 8px;
    width: calc(100% - 50px);
    margin: 0 auto;
    display: flex;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .status-list {
      display: flex;
      flex-wrap: nowrap;
      transition: all 0.5s ease;
      align-items: center;
      .status-item {
        background-color: white;
        cursor: pointer;
        border-radius: 8px;
        padding: 10px 14px;
        margin-right: 5px;
        border: 1px solid #e0e0e0;
        white-space: nowrap;
        // max-width: 20ch;
        // text-overflow: ellipsis;
        // overflow-x: hidden;
        &:hover {
          border: 1px solid #275edb;
        }
      }

      .item-actived {
        border: 1px solid #275edb;
      }
    }
  }
}
