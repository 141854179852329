.product-attribute-container {
  .filter-box {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    .search-input {
      width: 50%;
    }
  }

  .title-table {
    font-size: 0.9rem;
    font-weight: 700;
  }

  .filter-icon {
    color: #000000;
  }

  .text-link {
    color: #2246dc;
    font-weight: 700;
    cursor: pointer;
  }
}
