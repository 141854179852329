@import "../../../../../../styles/mixins";

.filter-history-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  .search-input {
    display: flex;
    width: 70%;
    gap: 80px;
    .icon {
      margin-right: 16px;
    }
    .custom-input {
      width: 100%;
    }
    .input-seller {
      height: fit-content;
      // padding: 8px;
    }
  }
  .btn-create {
    background-color: #2246dc;
    font-weight: 600;
    color: white;
    font-size: 14px;
  }
}

// ===============================Reponesive======================================

@include tablet_landscape() {
  .filter-history-container {
    .search-input {
      // width: 100%;
      gap: 0;
      .custom-input {
        margin: 0 12px;
      }
    }
  }
}

@include mobile_portrait() {
  .filter-history-container {
    padding: 0px;
    flex-wrap: wrap;
    .search-input {
      .hide {
        display: none;
      }
      .icon {
        margin-right: 0 !important;
      }
    }
    .btn-create {
      margin-top: 16px;
    }
  }
}
