.custom-modal-select-product {
  .custom-card-select-product {
    .ant-card-body {
      padding: 12px;
      .ant-space-align-center {
        align-items: baseline;
        .ant-list-item:nth-child(n + 4) {
          display: none;
        }
        .ant-list-item {
          padding: 6px 0;
        }
      }
    }
  }
}
