.form-warranty-request {
  .sub-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    padding: 15px 0 0 0;
  }
  .color-title {
    color: #052987;
  }

  .color-content {
    color: #2246dc;
    font-weight: bold;
  }
  .title-card {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }
  .space {
    width: 100%;
    justify-content: flex-end;
    background-color: #fff;
    border-radius: 8px;
  }
  .highlight {
  }
  .text-overflow {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}
