.modal-question-export-instock-vat {
  display: inline;
  .form-question-export-instock-vat {
    text-align: center;
    .title {
      font-weight: bold;
      font-size: 20px;
    }
    .title-info {
      text-align: center;
    }
  }
  // .ant-btn {
  //   height: 40px;
  //   color: #fff;
  //   border: 2px solid #2246dc;
  //   font-weight: 600;
  //   &:first-child {
  //     background-color: #fff;
  //   }
  //   &:last-child {
  //     background-color: #2246dc;
  //   }
  // }
}
