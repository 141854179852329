.filter-box {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  .search-input {
    width: 50%;
  }
  .btns {
    display: flex;
    gap: 20px;
  }
}

.menu {
  border-radius: 8px;
  .menu-header {
    background-color: #ffffff;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #d8dfea;
    height: 40px;
    p {
      margin: 0;
    }
    .title {
      font-weight: 600;
      font-size: 16px;
      color: #5b6673;
    }
    .setting {
      font-weight: 600;
      font-size: 14px;
      color: #262f3b;
      cursor: pointer;
      a {
        margin-left: 10px;
      }
    }
  }
  .panel-content {
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    background-color: #f7f8fb;
    gap: 8px;
    .checkbox-group {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}
