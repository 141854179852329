.custom-steps {
  .left-arrow,
  .right-arrow {
    border: 1px solid #e0e0e0;
    position: absolute;
    top: calc(50% - 15px);
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
      border: 1px solid #1953d8;
      color: white;
      background: #1953d8;
    }
  }
  .left-arrow {
    left: 0px;
  }

  .right-arrow {
    right: 0px;
  }

  .ant-steps {
    position: relative;
    padding-top: 52px;
    padding-bottom: 16px;
    .ant-steps-item {
      padding-right: 22px;
      .ant-steps-item-tail {
        margin-left: 58px;
      }
    }
    .time-zone {
      color: #052987;
      position: absolute;
      top: -52px;
      display: block;
      width: 116px;
    }
    .success {
      color: #00ab78;
    }
    .cancel {
      color: #d2434d;
    }
  }

  .list-steps-container {
    overflow-x: auto;
    width: calc(100% - 50px);
    margin: 0 auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
