.home {
  width: 100%;
  height: calc(100vh - 110px);
  background-color: #ffffff;

  .container {
    padding-top: 14%;
    padding-bottom: 13%;
  }

  .icon {
    width: 150px;
    height: 150px;
    color: #2246dc;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 700;
    .ha {
      color: #2246dc;
    }
  }
}
