@import "../../../../../../styles/mixins";

.button-group-of-2 {
  width: fit-content;
  display: flex;
  gap: 25px;
  justify-content: right;
  .ant-btn {
    border: 1px solid #2246dc;
    color: #2246dc;
    font-weight: 600;
    width: fit-content;
  }
  .btn-main-action {
    background-color: #2246dc;
    color: #ffffff;
    font-weight: 600;
  }
}

.button-group-status {
  display: flex;
  gap: 15px;
  .secondary-button {
    border: 1px solid #2246dc;
    color: #2246dc;
    font-weight: 600;
  }
  .btn-main-action {
    background-color: #2246dc;
    color: #ffffff;
    font-weight: 600;
  }
}

// ===============================Reponesive======================================
@include laptop() {
  .button-group-of-2 {
    width: 38%;
  }
  .button-group-status {
    margin: 20px 0;
  }
}

@include tablet_landscape() {
  .button-group-of-2 {
    margin-top: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
    .ant-btn {
      width: 100%;
    }
  }
}

@include tablet_portrait() {
  .button-group-of-2 {
    margin-top: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .ant-btn {
      width: 100%;
    }
  }
}
