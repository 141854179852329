.filter-profit-reconciliation-container {
  .block-input {
    display: flex;
    gap: 8px;
  }
  .custom-input {
    max-width: 500px;
  }
  .tags-list {
    display: flex;
  }
}
