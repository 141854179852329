.container {
  width: 100%;
  height: calc(100vh - 200px);
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .welcome {
      .title {
        font-size: 1.25rem;
        font-weight: 700;
      }
      .subTitle {
        font-size: 0.8rem;
        font-style: italic;
        color: #787878;
      }
    }
    .selection-group {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      margin-right: 20px;
    }
  }
  .charts {
    width: 100%;
    height: 100%;
  }
}
