.list-sales-return {
  .ant-table th {
    text-align: center !important;
    vertical-align: middle;
    font-weight: 600;
    color: #5b6673;
  }
  .ant-table td {
    .ant-tag {
      font-size: 13px;
    }
    .custom-tags-by-serial {
      background: #e7f0ff;
      padding: 4px 10px;
      border-radius: 12px;
      color: #2246dc;
      border: none;
      margin-bottom: 6px;
      border: 1px solid #2246dc;
    }
  }
  .ant-table-body {
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: #f8f8f8;
    }
    &::-webkit-scrollbar-thumb {
      background: #c2c2c2;
      border-radius: 20px;
      width: 6px;
    }
    border-bottom: 1px solid #f8f8f8;
  }
  .text-hidden-two {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .table-parent {
    .ant-table-row {
      cursor: pointer;
    }
  }
  .ant-table-content::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    border-radius: 20px;
    width: 4px;
  }

  .button-action-recommend {
    width: 125px !important;
    height: 35px;
    font-weight: 600;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 0 auto;
  }
  .button-action-complete {
    width: 165px !important;
  }
  .button-offers-recommend {
    border: 1px solid #2246dc;
    background: #2246dc;
    color: #fff !important;
    svg {
      width: 10px;
    }
  }
  .button-cancel-recommend {
    border: 1px solid #2246dc;
    background: #fff;
    color: #2246dc !important;
  }
  .header-card-table-list-product {
    .ant-card-head {
      background-color: #e7f0ff;
    }
  }

  .show-hidden {
    display: flex;
    justify-content: center;
    margin-bottom: -5px;
    margin-top: -10px;
    text-align: center;
    .ant-btn {
      padding: 0px 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
}
