.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 16px;
  grid-template-areas: "product-type product-type" "info-product info-product" "product-form product-form" "product-attributes product-attributes" "price-info price-info" "variant-manage variant-manage" "button-group button-group";
  .button-group {
    grid-area: button-group;
    > div {
      display: flex;
      justify-content: flex-end;
      background: rgba(255, 255, 255);
      padding: 8px 16px 8px 0px;
      box-shadow: 0 -3px 10px 0px rgb(0 0 0 / 10%);
      border-radius: 8px;
      > div {
        display: flex;
        gap: 16px;
      }
    }

    .buttons {
      margin: 0;
    }
  }
  // .product-type {
  //   grid-area: product-type;
  // }
  .info-product {
    grid-area: info-product;
  }

  .product-attributes {
    grid-area: product-attributes;
  }
  .price-info {
    grid-area: price-info;
  }
  .variant-manage {
    grid-area: variant-manage;
  }
  .product-form {
    grid-area: product-form;
  }
}
