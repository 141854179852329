.table-show-all-product {
  .ant-table-thead .ant-table-cell {
    text-align: center !important;
    vertical-align: middle;
    font-weight: 600;
    color: #5b6673;
    font-size: 14px;
  }
  .ant-table-tbody > tr > td {
    text-align: center !important;
  }
}
.title-show-all {
  position: absolute;
  bottom: 5px;
  right: 10px;
}
.search-input {
  .ant-form-item {
    margin-bottom: 0px !important;
  }
}
