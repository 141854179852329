@import "../../../../../../styles/mixins";

.account-container {
  font-size: 14px;
  .account-id {
    white-space: nowrap;
    font-weight: 600;
  }

  .status-on {
    color: #00ab78;
  }

  .status-off {
    color: #5b6673;
  }
  .custom-tag {
    color: #1953d8;
    border-radius: 8px;
    .label {
      font-weight: 600;
      color: #1953d8;
    }
    .ant-tag-close-icon {
      margin-left: 5px;
      background-color: #1953d8;
      width: 14px;
      height: 14px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 7px;
        path {
          fill: #fff;
        }
      }
    }
  }
  .ant-table-thead {
    .ant-table-cell {
      text-align: center;
    }
  }
  .filter-box {
    display: flex;
    // margin-bottom: 20px !important;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    .search-input {
      width: 50%;
      .custom-input {
        height: 45px;
      }
    }

    .create-btn {
      height: 40px;
      width: 120px;
      font-weight: 600;
    }
    .status-filter,
    .department-filter {
      white-space: nowrap;
      .filter {
        margin-left: 5px;
      }
    }
  }
}

.user-name {
  span {
    color: #ff0c5c;
  }
}

.role-tag {
  border: 1px solid #cecece;
  padding: 2px;
  border-radius: 5px;
  &:not(:last-child) {
    margin-right: 3px;
  }
}

// ===============================Reponesive====================================
@include laptop() {
  .account-container {
    .filter-box {
      .search-input {
        width: 35%;
      }
    }
  }
}

@include tablet_landscape() {
  .account-container {
    .filter-box {
      .search-input {
        width: 35%;
      }

      .create-btn,
      .department-filter {
        margin-top: 10px;
      }
    }
  }
}

@include tablet_portrait() {
  .account-container {
    .filter-box {
      .search-input {
        width: 35%;
      }

      .status-filter,
      .department-filter {
        .filter-label {
          display: none;
        }
      }

      .create-btn,
      .department-filter {
        margin-top: unset;
      }
    }
  }
}

@include mobile_landscape() {
  .account-container {
    .filter-box {
      .create-btn {
        margin-top: 15px;
      }
    }
  }
}

@include mobile_portrait() {
  .account-container {
    .filter-box {
      flex-direction: column;
      .search-input {
        width: 100%;
        margin-bottom: 15px;
      }

      .status-filter,
      .department-filter {
        white-space: nowrap;
        .filter-label {
          display: none;
        }

        .filter {
          margin-left: 0px;
          width: calc(100vw - 20px);
          margin-bottom: 15px;
        }
      }

      .create-btn {
        width: calc(100vw - 20px);
        margin-top: unset;
      }
    }
  }
}
