.table-categories {
  .category-list {
    display: flex;
    gap: 16px;
    align-items: center;

    .logo {
      width: 32px;
      height: 32px;
    }
    .title {
      margin: 0;
      .total-active-product {
        color: #2246dc;
      }
    }
  }
}

.highlights {
  background-color: rgb(0 0 0 / 12%);
}
