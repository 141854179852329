.detail-recommend-import {
  .form-footer {
    position: fixed;
    bottom: 0;
    width: calc(100% - 40px);
    display: flex;
    background: #fff;
    border-radius: 8px;
    padding: 8px 6px;
    justify-content: end;
    .ant-card-body {
      display: flex;
      padding: 0;
      gap: 16px;
    }
  }
  .ant-card {
    margin-top: 10px;
    .ant-card-head {
      background-color: #e7f0ff;
      .ant-typography {
        color: #052987;
        font-weight: 600;
      }
    }
  }
  .form-info-provider {
    margin-top: -20px;
    .ant-row {
      margin-top: 10px;
    }
  }
  .form-head {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  .card-container-info {
    display: flex;
    flex-direction: column;
  }

  .card-container-info > div {
    flex-grow: 1;
  }
  .card-parent > .ant-card-body {
    padding: 0 10px 10px 10px;
  }
  .card-children .ant-card-head {
    background-color: #fff;
  }
  .ant-tag {
    background: #e7f0ff;
    color: #2246dc;
    border: none;
    padding: 2px 10px;
  }
}
.form-input-checkbox-add-product {
  .ant-row {
    margin-bottom: 0px;
    font-weight: 600;
    .ant-form-item-control-input {
      min-height: 0;
    }
  }
}
.date-picker-form-add-product {
  .ant-picker {
    width: 100%;
  }
}
