.content-vat-info {
  .labelLegalRepresentative,
  .labelRegisteredBusinessAddress {
    .ant-col {
      justify-content: center !important;
      label {
        height: 42px !important;
      }
    }
  }
}
