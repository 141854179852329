.info-detail {
  color: #262f3b;
  .custom-row {
    margin-bottom: 16px;
  }
  .collapse-parent-info-detail > .ant-collapse-item > .ant-collapse-header {
    padding: 0 !important;
    font-size: 16px !important;
    &::before {
      display: none;
      content: "";
    }
    &::after {
      display: none;
      content: "";
    }
  }
  .space-card,
  .space-product {
    .ant-space-item {
      width: 100%;
    }
  }
  .ant-collapse-header {
    padding: 12px 16px !important;
    font-size: 15px;
    font-weight: 600;
    gap: 50px;
    svg {
      path {
        stroke-width: 100;
        fill: #262f3b;
        stroke: #262f3b;
      }
    }
  }

  .ant-collapse-content-box {
    // padding: 16px 0px !important;
    .collapse-content-left {
      border-right: 1px dashed #9cadc3;
    }
    .collapse-content-right {
      padding-left: 24px !important;
    }
  }
  .info-detail-row {
    padding: 12px;
    border-radius: 12px;
  }
  .info-detail-product {
    border: 1px solid #d8dfea;
    padding: 8px 12px !important;
    border-radius: 8px;
    overflow: hidden;
  }
  .collapse-parent-info-detail > .ant-collapse-item > .ant-collapse-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
  }
}
