.ant-select {
  width: 100% !important;
}
.ant-col-24.ant-form-item-label {
  padding: 0;
}

.sub-title {
  font-size: 14px;
  font-weight: 600;
}

.add-customer-title {
  font-size: 20px;
  font-weight: 600;
}
