.input-tag-container-modal-select {
  display: flex;
  align-items: center;
  .isHidden {
    // max-height: 62px;
    // overflow: hidden;
  }
  .input-tag-input {
    flex: 1;
    width: auto;
  }

  .input-tag-tags {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .custom-tags-by-serial {
    overflow: hidden;
    background-color: #e7f0ff;
    border: 1px solid #2246dc;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3px;
    height: 26px !important;
    color: #000;
    font-size: 14px;
  }
  .ant-tag-close-icon {
    font-size: 10px;
    color: #2246dc;
    font-weight: 800;
  }
  .input-tag-tag,
  .input-value {
    margin-right: 4px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }
  .input-enter-serial {
    font-size: 14px;
    padding: 0px 8px;
    height: 26px !important;
    background-color: #e7f0ff;
    border: 1px solid #2246dc;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    svg {
      fill: #2246dc;
      font-size: 10px;
    }
  }
  .ant-checkbox-group {
    display: grid;
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 5px;
    column-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
    .ant-checkbox-group-item {
      margin-right: 0;
      border: 1px solid #1953d8;
      padding: 3px 5px;
      display: flex;
      justify-content: center;
      border-radius: 8px;
      background-color: #e7f0ff;
      font-size: 13px;
      font-weight: 600;
      position: relative;
      margin-bottom: 5px;
      .ant-checkbox {
        display: none !important;
      }
      .ant-checkbox-checked {
        position: absolute;
        right: -7px;
        top: -7px;
        display: none !important;
        .ant-checkbox-inner {
          border-radius: 100px;
          background-color: green;
          border: 1px solid green;
          :hover {
            border: 1px solid green;
          }
        }
      }
    }
  }
}
.form-model-list-tags-export-serial {
  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 5px;
    .ant-checkbox-group-item,
    .serial-checkbox-custom {
      border: 1px solid #1953d8;
      padding: 3px 5px;
      display: flex;
      justify-content: center;
      border-radius: 8px;
      background-color: #e7f0ff;
      font-size: 13px;
      font-weight: 600;
      position: relative;
      margin-bottom: 5px;
      .ant-checkbox {
        display: none;
      }
      .ant-checkbox-checked {
        position: absolute;
        right: -7px;
        top: -7px;
        display: none;
        .ant-checkbox-inner {
          border-radius: 100px;
          background-color: green;
          border: 1px solid green;
          :hover {
            border: 1px solid green;
          }
        }
      }
    }
  }
}
