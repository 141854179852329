.custom-tabs {
  // restyle
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    left: 0px;
    width: 82px;
    height: 4px;
    background: #2553bb;
    border-radius: 8px 8px 0px 0px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .title {
    color: #2246dc;
  }

  // style
  .title {
    font-size: 16px;
    line-height: 22px;
    color: #5b6673;
  }
}
