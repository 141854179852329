@import "../../../styles/mixins";

.wrapper {
  .wrapper-info,
  .wrapper-tabs {
    background-color: white;
    border-radius: 8px;
    .parent.no-row {
      grid-row-gap: 0 !important;
    }
    .parent {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, 0fr);
      grid-row-gap: 12px;
      grid-column-gap: 12px;
      justify-items: start;
      align-content: stretch;
      .text-bold {
        font-weight: 600;
        text-align: right;
      }
      .see-more {
        color: blue;
        font-style: italic;
        cursor: pointer;
      }

      .green {
        color: #00ab78;
      }
      .yellow {
        color: #ef9351;
      }
      .red {
        color: #d2434d;
      }
    }
  }
  .wrapper-info {
    .hr {
      border-bottom: 1px solid #d9d9d9;
    }

    .padding {
      padding: 20px;
      gap: 100px;
      .title {
        font-weight: 600;
        font-size: 18px;
        padding-right: 30px;
      }
      .status-true {
        color: #00ab78;
        background: #edf9f0;
        border-radius: 8px;
        padding: 8px 24px;
      }

      .status-false {
        color: #d2434d;
        background: #feefef;
        border-radius: 8px;
        padding: 8px 24px;
      }
      .logo-content {
        .avatar {
          text-align: center;
        }
      }
    }
  }
  .wrapper-tabs {
    margin-top: 20px;
    padding: 20px;
    // restyle
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      left: 0px;
      width: 82px;
      height: 4px;
      background: #2553bb;
      border-radius: 8px 8px 0px 0px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .title {
      color: #2246dc;
    }

    // style
    .title {
      font-size: 16px;
      line-height: 22px;
      color: #5b6673;
    }
    .title-column {
      font-weight: 600;
    }

    .order-code {
      color: #2246dc;
      font-weight: 600;
    }
  }
}

@include tablet_landscape() {
  .wrapper {
    .wrapper-info,
    .wrapper-tabs {
      .parent {
        grid-template-columns: repeat(1, 1fr) !important;
      }
    }
  }
}
