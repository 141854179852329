.custom-descriptions {
  .ant-descriptions-header {
    margin-bottom: 4px !important;
  }
  .ant-descriptions-view {
    padding: var(--padding-view);
  }
  .ant-descriptions-item-label {
    width: var(--label-width, 30%);
    font-weight: bold;
  }
  .ant-descriptions-item-content {
    width: var(--content-width, 70%);
  }
}
