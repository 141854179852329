@import "../../../../../styles/mixins";

.form-container {
  padding-left: 50px;
  .button-group {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
  .form-group {
    gap: 20px;
    .ant-form-item {
      margin-bottom: 30px;
    }
    .form-label {
      font-weight: 700;
      word-wrap: break-word;
      white-space: initial;
    }
    .attribute-value-list-extra {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .value-tag {
      background: #e7f0ff;
      padding: 6px 12px;
      border-radius: 25px;
      border-color: #2246dc;
      font-size: 12px;
    }
    .apply-all-category {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .ant-form-item {
        margin-bottom: 0;
      }
    }
    .in-used-category {
      margin-right: 25px;
    }
  }
}

// ===============================Reponesive====================================
@include tablet_landscape() {
  .form-container {
    padding-left: 0px;
  }
}
