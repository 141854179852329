.table-selection {
  .group-btn {
    display: flex;
    align-items: center;
    svg {
      font-size: 18px;
    }
    .select-all-btn {
      margin-right: 15px;
      cursor: pointer;
      &:hover {
        color: #1953d8;
      }
    }

    .select-all-btn-active {
      color: #1953d8;
    }
  }
  .selected-info {
    background-color: white;
    text-align: center;
    padding: 10px 0;
    margin: 10px auto 0;
    border: 1px dashed #d8dfea;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    width: 100%;
    color: #262f3b;
    span {
      color: #000;
      cursor: pointer;
    }
  }
}
