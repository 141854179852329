.custom-drawer-edit-product {
  .vat-info-container {
    .add-on-after {
      color: #ffffff;
      width: 22px;
    }
    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
    .vat {
      label {
        width: 100%;
        .label-vat {
          height: 22px;
          display: flex;
          align-content: center;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          .ant-form-item {
            margin: 0;
          }
        }
      }
    }
    .radio-group-type-group {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-bottom: 24px;
      .ant-radio-button-wrapper-checked {
        background-color: #e7f0ff;
      }
      .title::after {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
      }

      .ant-form-item {
        margin-bottom: 0px;
      }
    }
    .vat-value-group {
      flex-wrap: nowrap;
      align-items: end;
      gap: 8px;
    }
  }
  .pricePolicyUpdate {
    .ant-form-item {
      margin-bottom: 0;
    }
    .input-number-qty {
      width: 70px;
    }
    .input-number {
      width: 100px;
    }
  }
}
