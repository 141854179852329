.list-container {
  max-height: 700px;
  overflow-y: auto;
  .noti-container {
    padding: 10px;
    .time {
      color: #2246dc;
    }
  }
}
