.label-full-width {
  label {
    width: 100%;
  }
}
.custom-form-item {
  .ant-select-multiple {
    .ant-select-selection-item {
      background: #e7f0ff;
      color: #2246dc;
      font-weight: 600;
      .ant-select-selection-item-remove {
        color: #2246dc;
      }
    }
  }
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  // order: 2 !important;
}
