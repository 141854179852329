.create-recommend-import {
  .form-footer {
    position: fixed;
    bottom: 0;
    width: calc(100% - 40px);
    display: flex;
    background: #fff;
    border-radius: 8px;
    padding: 8px 6px;
    justify-content: end;
    .ant-card-body {
      display: flex;
      padding: 0;
      gap: 16px;
    }
  }
  .ant-table th {
    text-align: center !important;
    vertical-align: middle;
    font-weight: 600;
    color: #5b6673;
  }
  .ant-form-item-extra {
    font-size: 12px;
    font-style: italic;
  }
  .ant-card {
    margin-top: 10px;
    .ant-card-head {
      background-color: #e7f0ff;
      .ant-typography {
        color: #052987;
        font-weight: 600;
      }
    }
  }
  .form-text-center {
    input {
      text-align: center;
    }
  }
  .form-border-radius-left {
    .ant-select-selector {
      border-radius: 0 8px 8px 0;
    }
  }
  .form-border-radius-right {
    border-radius: 8px 0px 0px 8px;
  }
  .ant-form-item-explain-error {
    div {
      position: absolute;
      min-width: max-content;
      width: 100%;
      text-align: left;
    }
  }
  .form-info-provider {
    margin-top: -20px;
    .ant-row {
      margin-top: 10px;
    }
  }
  .form-head {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  .card-container-info {
    display: flex;
    flex-direction: column;
  }

  .card-container-info > div {
    flex-grow: 1;
  }
  .form-tags {
    .ant-select-selection-item {
      color: #2246dc;
      background-color: #e7f0ff;
      border: 1px solid #e7f0ff;
      margin: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      .ant-select-selection-item-remove {
        background-color: #2246dc;
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        path {
          fill: #fff;
        }
      }
    }
  }
}
.form-input-checkbox-add-product {
  .ant-row {
    margin-bottom: 0px;
    font-weight: 600;
    .ant-form-item-control-input {
      min-height: 0;
    }
  }
}
.date-picker-form-add-product {
  .ant-picker {
    width: 100%;
  }
}
