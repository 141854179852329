.order-code-and-order-status {
  background-color: #ffffff;
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  gap: 16px !important;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow: auto;
  align-items: flex-start;
  .block-space {
    display: inline-block;
  }
  .info-order-waiting-for-shipping {
    display: flex;
    align-self: start;
    flex-direction: column;
    background-color: #f7f8fb;
    border-radius: 12px;
    padding: 12px;
    height: 100%;
    width: 100%;
    font-weight: 600;
    .row-item {
      align-items: center;
      display: flex;
      .title-info {
        font-size: 13px;
      }
      .title-content {
        color: #052987;
      }
      .view-detail {
        font-size: 12px;
        font-weight: 400;
        color: #2246dc;
        font-style: italic;
        cursor: pointer;
      }
    }
  }
}
