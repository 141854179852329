.profile-container {
  .page-title {
    font-weight: 600;
    font-size: 22px;
  }
  .page-content {
    padding: 40px 24px;
    min-height: calc(100vh - 210px);
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .ant-tabs-nav {
    height: calc(100vh - 210px);
    width: 18%;
    border-right: 1.5px dashed #cacaca;
    border-radius: 0% !important;
    .ant-tabs-tab {
      padding: 15px 25px;

      .ant-tabs-tab-btn {
        font-size: 16px;
      }
    }
    .ant-tabs-tab-active {
      background-color: #e7f0ff;
      border-radius: 24px;
      padding: 15px 24px;
    }
    .ant-tabs-ink-bar {
      background-color: transparent;
    }
  }
}
