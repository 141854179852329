.container-spilt-order {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "order-code order-code" "order-info order-info" "custom-table custom-table" ". bill";
  // flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 40px;
  margin-bottom: 20px;

  .order-code {
    grid-area: order-code;
    text-transform: uppercase;
    font-weight: bold;
    .code {
      color: #2246dc;
    }
  }
  .order-info {
    grid-area: order-info;
    text-transform: uppercase;
    color: #5b6673;
  }
  .custom-table {
    grid-area: custom-table;
    margin-bottom: 16px;
    .input-counter {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      gap: 4px;
    }
  }
  .bill {
    grid-area: bill;
    display: grid;
    width: 100%;
    grid-template-columns: 4fr 3fr;
    text-align: end;
    justify-items: end;
    p {
      margin: 0;
      color: #5b6673;
    }
    p.text-blue {
      color: #2246dc;
      font-weight: bold;
    }
  }
}

.modal-split-confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  .modal-warning-icon {
    height: 40px;
    width: 40px;
  }
  i {
    text-align: center;
  }
}
