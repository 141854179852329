@import "../../../styles/mixins";

.new-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  .left-col {
    width: 50%;
    background-image: url("../../../assets/images/login-bg.png");
    background-size: 100% 100%;
  }

  .right-col {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .form {
      width: 400px;
      .logo {
        height: 80px;
        width: 80px;
        background-image: url("../../../assets/images/key-icon.PNG");
        background-size: 100% 100%;
        margin-bottom: 10px;
      }

      .back {
        font-size: 16px;
      }

      .form-tile {
        font-weight: 600;
        font-size: 22px;
        margin-top: 10px;
      }

      .notify {
        margin-bottom: 5px;
        color: #5b6673;
      }

      .password-rule {
        padding-left: 20px;
        color: #5b6673;
      }

      .submit-btn {
        width: 100%;
        background-color: #1953d8;
        height: 44px;
        border-radius: 7px;
      }
    }
  }
}

// ===============================Reponesive====================================
@include tablet_portrait() {
  .new-container {
    .left-col {
      display: none;
    }

    .right-col {
      width: 100%;
    }
  }
}

@include mobile_portrait() {
  .new-container {
    .right-col {
      .form {
        width: 90%;
      }
    }
  }
}
