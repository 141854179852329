.modalMessage {
  text-align: center;
  font-size: 1rem;
}
.modalTitle {
  text-align: center;
  font-size: 1.2rem;
  // font-weight: 600;
}
.countSeller {
  font-weight: bold;
}
