.content-genera-info {
  .labelIdNumber {
    .ant-col {
      justify-content: center !important;
      label {
        height: 42px !important;
      }
    }
  }
}
