.title {
  text-transform: uppercase;
}

.title.filter {
  display: flex;
  align-items: center;
}

.label-price {
  display: flex;
  justify-content: center;
}

.add-variant {
  color: #1953d8;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 0 16px 32px;

  .icon {
    font-size: 200%;
  }
  .text {
    font-weight: 700;
  }
}

.add-variant:hover {
  cursor: pointer;
}
