.input-align-end {
  input {
    text-align: end;
  }
}

.input-align-center {
  input {
    text-align: center;
  }
}
