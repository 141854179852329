.custom-card-ant {
  .ant-tabs-nav-list {
    padding: 2px 16px;
    .ant-tabs-tab {
      padding: 12px 0;
    }
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    left: 0px;
    width: 82px;
    height: 4px;
    background: #2553bb;
    border-radius: 8px 8px 0px 0px;
  }
}
