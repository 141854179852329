.modal-download-file-update-price {
  display: inline;
  width: 380px !important;
  height: 266px !important;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .modal-children {
    margin-bottom: 30px;
    .label-custom {
      display: flex;
    }
    .ant-radio-checked .ant-radio-inner {
      border: none;
      &::after {
        width: 16px;
        height: 16px;
        left: 0;
        top: 0;
        background-image: url(../../../../../../../assets/icons/Checked.svg);
        background-position: 3px 3px;
        background-repeat: no-repeat;
      }
    }
  }
  .ant-btn {
    width: 120px !important;
    height: 40px !important;
    color: #fff;
    &:first-child {
      background-color: #afbbca;
      border: 1px solid #afbbca;
      &:hover {
        border: none;
      }
    }
    &:last-child {
      background-color: #2246dc;
    }
  }
  .ant-radio-wrapper-checked {
    color: #2246dc;
  }
}
