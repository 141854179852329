.campaign-product-promo {
  .ant-card-head {
    background-color: #e7f0ff;
    font-weight: 600;
    font-size: 16px;
    min-height: auto;
    // padding: 15px 24px;
  }
  .ant-card-head-title {
    padding: 0;
    .ant-typography {
      color: #262f3b;
    }
  }
  .table-show-all-product {
    .ant-table-thead .ant-table-cell {
      text-align: center !important;
      vertical-align: middle;
    }
  }
  .ant-image-mask {
    border-radius: 12px;
  }
  .form-footer {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    position: fixed;
    bottom: 0px;
    width: calc(100% - 40px);
    display: flex;
    background: #fff;
    border-radius: 8px;
    padding: 8px 6px;
    justify-content: end;
    .ant-card-body {
      display: flex;
      padding: 0;
      gap: 16px;
    }
  }
}
