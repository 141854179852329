.button-group-seller-action {
  // height: 50px;
  display: flex;
  justify-content: flex-end;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 -3px 10px 0px rgb(0 0 0 / 10%);
  gap: 20px;
  .ant-form-item {
    margin: 0;
  }
  .cancel-btn {
    border-color: #2246dc;
    color: #2246dc;
  }
}
