@import "../../../../../styles/mixins";

.notification-menu {
  width: 600px;
  padding: 0px;
  border-radius: 8px;
  .noti-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #d8dfea;
    border-radius: 8px 8px 0 0;
    > span {
      font-size: 16px;
      font-weight: 600;
    }
    .header-title {
      display: block;
    }
  }
  .list {
    .list-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > b {
        width: calc(100% - 120px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      > span {
        width: 110px;
        padding-right: 10px;
      }
    }
  }
  .noti-watch-all {
    width: 100%;
    border-top: 1px solid #f0f0f0;
    border-radius: 0px;
  }
}
// ===============================Reponesive====================================

@include tablet_landscape() {
  .notification-menu {
    width: 300px;
    .noti-header {
      justify-content: right;
      .header-title {
        display: none;
      }
    }
  }
}
