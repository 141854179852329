.noti-card {
  width: 100%;
  background: #ffffff;
  border: 1px solid #d8dfea;
  border-radius: 12px;
}
.noti-card:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.noti-card.active {
  background: #e7f0ff;
}

.noti-card.active:hover {
  background: #ccdfff;
  border: 1px solid #bbcfee;
}
