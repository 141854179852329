@import "../../../../../styles/mixins";

.product-detail-container {
  .button-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  .card-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    text-transform: uppercase;
    color: #5b6673;
    margin: 15px 0;
  }
  .card-container {
    background-color: #ffffff;
    border-radius: 8px;
  }
  .card-group-60-40 {
    display: grid;
    grid-template-columns: calc(60% - 25px) 40%;
    grid-column-gap: 25px;
  }
  .button-product-price-logs {
    color: #2246dc;
    font-weight: 600;
    border-color: #2246dc;
  }
  .stock-info {
    .button {
      width: 100% !important;
      justify-content: center !important;
    }
  }
  .block-product-detail {
    .btn-see-more {
      button {
        color: rgba(38, 118, 255, 1);
        border-color: rgba(34, 70, 220, 1);
      }
    }
  }
}

// ===============================Responsive====================================
// @include tablet_portrait() {
//   .product-detail-container {

//   }
// }

// @include mobile_portrait() {
//   .product-detail-container {

// }
