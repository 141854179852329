.list-watch-price-log-detail {
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-table td,
  .ant-table th {
    text-align: center;
    vertical-align: middle;
    .anticon svg {
      color: #5b6673;
    }
  }
  .icon-new-vat {
    color: #32bf46;
    svg {
      path {
        fill: #32bf46;
      }
    }
  }
  .ant-table-thead .ant-table-cell {
    text-align: center !important;
  }
  .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f0f2f5;
    padding: 0;
    border: none;
  }
  .ant-select-selection-placeholder {
    color: red;
  }
  .ant-row {
    .tags-render {
      width: auto !important;
      .ant-select-selection-item {
        background-color: #e5e5e5;
        border: 1px solid #afafaf;
        color: #afafaf;
      }
    }
    .ant-select-selection-item {
      cursor: default !important;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      cursor: default !important;
    }
  }
  .header-box {
    background: #e9eef5;
    padding: 10px 20px;
    margin: 20px 0;
    border-radius: 8px;
  }
  .CREATED_AT_DESC path:first-child {
    fill: #1953d8 !important;
    stroke: #1953d8 !important;
  }
  .CREATED_AT_ASC path:last-child {
    fill: #1953d8 !important;
    stroke: #1953d8 !important;
  }
}
