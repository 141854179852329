@import "../../styles/mixins";

.period-filter {
  white-space: nowrap;
  .filter {
    margin-left: 5px;
  }
}

// ===============================Reponesive====================================

@include tablet_portrait() {
  .period-filter {
    .filter-label {
      display: none;
    }
  }
}

@include mobile_portrait() {
  .period-filter {
    white-space: nowrap;
    .filter-label {
      display: none;
    }

    .filter {
      margin-left: 0px;
      width: calc(100vw - 20px);
      margin-bottom: 15px;
    }
  }
}
