$primary: #052987;
$warning: #ef9351;
$tag: #2246dc;
$error: #d2434d;
$grey: #5b6673;

.custom-table {
  .title {
    font-weight: 600;
  }
  .sub-title {
    color: #ef9351 !important;
  }
  .custom-select {
    width: 150px;
    margin: auto;
  }
  .ant-form-item {
    margin: 0 !important;
  }
}
