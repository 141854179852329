@import "../../../styles/mixins";
$primary-color: #1953d8;

.setting-container {
  .page-title {
    font-weight: 600;
    font-size: 22px;
  }
  .page-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    .title {
      font-weight: 600;
      font-size: 16px;
      color: #5b6673;
    }

    .setting-content {
      display: grid;
      grid-template-columns: calc(100% / 3) calc(100% / 3) auto;
      .setting-box {
        .card {
          display: flex;
          align-items: center;
          .card-icon {
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            background: #e7f0ff;
            border-radius: 8px;
            svg {
              color: $primary-color;
              font-size: 20px;
            }
          }

          .card-content {
            flex: 1;
            a {
              color: $primary-color;
              font-weight: 600;
              font-size: 16px;
            }
            .desciption {
              margin: 0;
              font-size: 14px;
            }
          }
        }

        .create-acc-btn {
          margin-left: 60px;
          margin-top: 20px;
        }
      }
    }
  }
}

// ===============================Reponesive====================================\

@include laptop() {
  .setting-container {
    .page-content {
      .setting-content {
        grid-template-columns: 50% auto;
        .setting-box {
          &:not(:last-child) {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}

@include tablet_portrait() {
  .setting-container {
    .page-content {
      .setting-content {
        grid-template-columns: auto;
      }
    }
  }
}
