.custom-table-list-campaign {
  .custom-header {
    padding: 6px 12px;
    background: rgba(216, 223, 234, 1);
    .red-text {
      color: red;
    }
    .blue-text {
      color: blue;
    }
  }
}
