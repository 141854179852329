.charts {
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
    // min-height: 950px;
    border-width: 0px;
  }
}
