.custom-view-document-files {
  width: fit-content;
  .ant-image {
    border: 1px solid #d9d9d9;
  }
  .img-size {
    margin: 4px;
    width: 105px;
    height: 105px;
    border-radius: 8px;
  }
  .file-size {
    margin-bottom: 6px;
    width: 115px;
    height: 115px;
    border: 1px solid #d9d9d9;
    padding: 6px;
  }
}
