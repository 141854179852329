.custom-btn-see-more {
  .button-see-more {
    button {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      span {
        margin: 0px;
      }
      .anticon-caret-down {
        margin-left: 4px;
        margin-top: 2px;
      }
    }
  }
}
