$primary: #052987;
$warning: #ef9351;
$tag: #2246dc;
$error: #d2434d;
$borderRight: 1px dashed #9cadc4;
$white-grey: #f7f8fb;
$gray: #5b6673;

.title-card {
  font-weight: 600;
  color: #052987;
}
.sub-title-card {
  color: $primary;
}
.border-right {
  border-right: $borderRight;
}
.summary {
  color: $primary;
  font-weight: 600;
}
.lack {
  color: $error !important;
}
