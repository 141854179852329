@import "../../styles/mixins";

.layout {
  .side-bar {
    // width: 225px;
    background-color: white;
    height: 100vh;
    position: fixed;
    left: 0;
    transition: all 0.25s ease;
    z-index: 1000;
    display: none;
    .logo {
      // height: 64px;
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #eeeeee;
      .img {
        max-width: 100%;
        height: 100%;

        img {
          width: 42px;
          height: 42px;
        }
      }
      .closed {
        width: 0px;
      }
      .opened {
        width: 225px;
      }
    }
    .menu {
      height: 100%;
      .sub-menu {
        margin: 0;
        .menu-item {
          margin: 0;
        }
      }
    }
    .setting {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0px 24px;
      border-top: 1px solid #f1f1f1;
      overflow-x: hidden;
      font-weight: bold;
      margin: 0;
      &:hover {
        background-color: #ebf4ff;
      }
      svg {
        margin-right: 10px;
        font-size: 20px;
      }
      span {
        white-space: nowrap;
      }
    }
  }
  .site-layout {
    .header {
      background-color: white;
      padding: 0 20px;
      transition: all 0.25s ease;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 5;
      // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
      .menu-desktop {
        width: 100%;
        box-shadow: none;
        align-items: center;
        justify-content: start;
        // height: 100%;
        // overflow: inherit;
        ::after {
          right: 13px;
        }
        .ant-menu-submenu {
          .ant-menu-submenu-title {
            display: flex;
            align-items: center;
          }
        }
        .ant-menu-submenu-active {
          transition: background-color 0.5s ease;
          &:hover {
            .ant-menu-submenu-title {
              .icon-potential-customer {
                svg {
                  path {
                    fill: #1953d8;
                  }
                  circle {
                    stroke: #1953d8;
                  }
                  rect {
                    fill: #1953d8;
                  }
                }
              }
            }
          }
        }
        .ant-menu-submenu-selected {
          .ant-menu-submenu-title {
            .icon-potential-customer {
              svg {
                path {
                  fill: #1953d8;
                }
                circle {
                  stroke: #1953d8;
                }
                rect {
                  fill: #1953d8;
                }
              }
            }
          }
        }
      }
      .logo-desktop {
        // height: 64px;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #eeeeee;
        .img {
          max-width: 100%;
          height: 100%;
          img {
            width: 42px;
            height: 42px;
          }
        }
      }
      .trigger {
        font-size: 22px;
      }
      .mobile-menu {
        display: none;
        align-items: center;
        svg {
          font-size: 30px;
        }
      }
      .right-part {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: right;
        gap: 15px;
        .icon-style {
          color: #242424;
        }
        .icon-container {
          background-color: #f1f1f1;
        }
        .user-info {
          display: flex;
          align-items: center;
          cursor: pointer;
          .info {
            margin-right: 5px;
            .user-name {
              margin: 0;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
            }
            .user-role {
              margin: 0;
              line-height: 18px;
              font-size: 12px;
            }
          }
        }
      }
    }
    .header-opened {
      width: 100%;
    }
    .content {
      padding: 20px;
      min-height: calc(100vh - 64px);
      transition: all 0.25s ease;
      margin-top: 64px;
    }

    .content-opened {
      margin-left: 0px;
    }
  }
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #f8f8f8;
  }
  &::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    border-radius: 20px;
    width: 6px;
  }
  .ant-menu-horizontal {
    border: none !important;
  }
}
.header-menu {
  border-radius: 8px;
  .menu-item {
    padding: 10px 25px;
    a {
      svg {
        font-size: 16px;
      }
      span {
        font-size: 14px;
        line-height: 20px;
        margin-left: 10px;
      }
    }
  }
}
::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  background-color: #f8f8f8;
}
::-webkit-scrollbar-thumb {
  background: #c2c2c2;
  border-radius: 20px;
  width: 6px;
}
// ===============================Reponesive====================================
@include tablet_portrait() {
  .layout {
    .side-bar {
      width: 225px;
      display: block;
    }
    .mask {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.5);
      z-index: 10;
      transition: all 0.25s ease;
    }
    .mask-closed {
      opacity: 0;
      visibility: hidden;
    }
    .mask-opened {
      opacity: 1;
      visibility: visible;
    }
    .side-bar-closed {
      left: -270px;
    }
    .side-bar-opened {
      left: 0px;
    }
    .site-layout {
      .header {
        .trigger {
          display: none;
        }
        .mobile-menu {
          display: flex;
        }
        .right-part {
          .avatar {
            margin-right: 0;
          }
          .user-info {
            .info {
              display: none;
            }
          }
          .down-arrow {
            display: none;
          }
        }
      }
      .header-closed,
      .header-opened {
        margin-left: 0px;
        width: calc(100%);
      }
      .content-closed,
      .content-opened {
        margin-left: 0px;
      }
    }
  }
}
@include mobile_portrait() {
  .layout {
    .site-layout {
      .header {
        padding: 20px 10px;
      }
      .content {
        padding: 20px 10px;
      }
    }
  }
}
