.custom-activity-log-potential {
  margin-left: 16px;
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-timeline {
    .ant-timeline-item-head {
      background-color: transparent;
    }
    .item-first {
      padding-bottom: 0 !important;
    }
  }
  .ant-skeleton {
    padding-top: 16px;
    .ant-skeleton-content {
      .ant-skeleton-title {
        margin: 0 !important;
        width: 100% !important;
      }
      .ant-skeleton-paragraph {
        margin: 0 !important;
      }
    }
  }
}
