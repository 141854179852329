.product-attributes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 64px;
}

.input-predefined {
  label {
    width: 100%;
    .label-input-predefined {
      height: 32px;
      display: flex;
      align-content: center;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .title {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
      .ant-form-item {
        margin: 0;
      }
    }
  }
}

.input-free-text {
  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    height: 32px;
    display: flex;
    align-items: center;
  }
}
