.purchase-order-detail-page {
  .ant-table th {
    text-align: center !important;
    vertical-align: middle;
    font-weight: 600;
    font-size: 13px;
    color: #5b6673 !important;
  }
  .ant-table td {
    font-size: 13px;
  }
  .form-footer {
    position: fixed;
    bottom: 0;
    width: calc(100% - 40px);
    display: flex;
    background: #fff;
    border-radius: 8px;
    padding: 8px 6px;
    justify-content: end;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    .ant-card-body {
      display: flex;
      padding: 0;
      gap: 16px;
    }
  }
  .ant-alert {
    font-size: 13px;
  }
  .ant-card {
    margin-top: 10px;
    .ant-card-head {
      background-color: #e7f0ff;
      .ant-card-head-title {
        padding: 12px 0px;
        .ant-typography {
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }
  .form-info-provider {
    margin-top: -20px;
    .ant-row {
      margin-top: 10px;
    }
  }
  .form-head {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  .card-container-info {
    display: flex;
    flex-direction: column;
  }

  .card-container-info > div {
    flex-grow: 1;
  }
  .card-parent > .ant-card-body {
    padding: 0 10px 10px 10px;
  }
  .card-children .ant-card-head {
    background-color: #fff;
  }
  .ant-tag {
    background: #e7f0ff;
    color: #2246dc;
    border: 1px solid #2246dc;
    padding: 2px 8px;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 11px;
  }
}
.form-input-checkbox-add-product {
  .ant-row {
    margin-bottom: 0px;
    font-weight: 600;
    .ant-form-item-control-input {
      min-height: 0;
    }
  }
}
.date-picker-form-add-product {
  .ant-picker {
    width: 100%;
  }
}
