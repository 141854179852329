.custom-pagination-prev-next {
  .ant-pagination {
    margin-top: 8px;
    text-align: right;
    .ant-pagination-prev {
      display: none;
    }
    .ant-pagination-next {
      display: none;
    }
    .ant-pagination-item {
      background-color: transparent;
      border: none;
      margin: 0;
    }
  }
  .pagination-list-button {
    gap: 8px;
    .ant-btn {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
