@import "../../../../../../styles/mixins";

.common-info-container {
  padding: 20px;
  .ant-form {
    flex-direction: column;
  }
  .seller-class {
    display: flex;
    .seller-class-label {
      margin-top: 7px;
      margin-right: 20px;
      font-weight: bold;
    }
  }
  .field-content {
    background-color: white;
    border-radius: 8px;
    margin-bottom: 20px;
    height: 100%;
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #262f3b;
      border-bottom: 1px solid #d9d9d9;
      padding: 20px;
      margin-bottom: 0;
    }
    .no-account {
      padding: 20px;
    }
  }
  .form-content {
    background-color: white;
    border-radius: 8px;
    margin-bottom: 20px;
    height: 100%;
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #262f3b;
      border-bottom: 1px solid #d9d9d9;
      padding: 20px;
      margin-bottom: 0;
    }

    .logo {
      grid-area: 1 / 1 / 6 / 2;
      padding-top: 10px;
      .logo-content {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        .avatar {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          border: 1px solid #ebebeb;
          margin-bottom: 10px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .avatar-placeholder {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin-right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 40px;
          background-color: #e7f0ff;
          color: #9cadc3;
        }

        .upload {
          flex: 1;
          .upload-btn {
            color: #2246dc;
            border-color: #2246dc;
            margin-bottom: 10px;
          }
          .note {
            width: 200px;
            color: #5b6673;
          }
        }
      }
      .avatar-title {
        font-weight: 600;
      }
    }

    .form {
      padding: 20px;
      display: grid;
      grid-template-columns: 2fr repeat(3, 4fr);
      grid-column-gap: 20px;
      .ant-input-number {
        width: 100%;
      }
    }
  }

  .content-frame {
    // display: grid;
    // grid-template-columns: 4fr repeat(2, 3fr);
    // grid-template-rows: auto;
    // grid-column-gap: 20px;
    // grid-row-gap: 20px;
    margin-bottom: 20px;
    .common-info {
      grid-area: 1 / 1 / 2 / 4;
    }
    .category-list {
      grid-area: 2 / 1 / 3 / 2;
      .category-list-content {
        grid-template-columns: auto;
      }
    }
    .bill-info {
      grid-area: 2 / 2 / 4 / 4;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;

      .pay-method {
        grid-area: 1 / 1 / 2 / 2;
        .pay-method-content {
          display: flex;
          flex-direction: column;
        }
      }
      .contact-info-company {
        grid-area: 1 / 2 / 2 / 3;
      }
      .contact-info-personal {
        grid-area: 1 / 2 / 2 / 3;
        display: none;
      }
      .contact-info-content {
        display: flex;
        flex-direction: column;
      }
      .vat-info-company {
        grid-area: 2 / 1 / 3 / 3;
        .vat-info-company-content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, 1fr);
          grid-column-gap: 40px;
          // grid-row-gap: 20px;

          .radio-group-type-group {
            padding-top: 8px;
            display: flex;
            flex-direction: column;
            .ant-radio-button-wrapper-checked {
              background-color: #e7f0ff;
            }
            .ant-form-item {
              margin-bottom: 0px;
            }
            .ant-checkbox-wrapper {
              .title-checkbox {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
              }
            }

            .title-label::after {
              display: inline-block;
              margin-right: 4px;
              color: #ff4d4f;
              font-size: 14px;
              font-family: SimSun, sans-serif;
              line-height: 1;
              content: "*";
            }
          }
        }
      }
      .vat-info-personal {
        grid-area: 1 / 2 / 2 / 3;
        .vat-info-personal-content {
          display: flex;
          flex-direction: column;
          .radio-group-type-group {
            padding-top: 8px;
            display: flex;
            flex-direction: column;
            .ant-radio-button-wrapper-checked {
              background-color: #e7f0ff;
            }
            .ant-form-item {
              margin-bottom: 0px;
            }
            .ant-checkbox-wrapper {
              .title-checkbox {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
              }
            }

            .title-label::after {
              display: inline-block;
              margin-right: 4px;
              color: #ff4d4f;
              font-size: 14px;
              font-family: SimSun, sans-serif;
              line-height: 1;
              content: "*";
            }
          }
        }
      }
      .login-account-company {
        grid-area: 3 / 1 / 3 / 3;
        .login-account-content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, 1fr);
          grid-column-gap: 40px;
          grid-row-gap: 20px;
        }
      }
      .login-account-personal {
        grid-area: 2 / 1 / 3 / 3;
        .login-account-content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, 1fr);
          grid-column-gap: 40px;
          grid-row-gap: 20px;
        }
      }
    }
  }
}
.ant-btn .ant-btn-primary .btn-create {
  background-color: red;
}
// ===============================Reponesive====================================
@include mobile_landscape() {
  .common-info-container {
    .form-content {
      .logo {
        .logo-content {
          .avatar {
            width: 80px;
            height: 80px;
          }

          .avatar-placeholder {
            width: 80px;
            height: 80px;
          }
        }
      }

      .form {
        grid-template-columns: 50% auto;
      }
    }
  }
}

@include mobile_landscape() {
  .common-info-container {
    .form-content {
      .form {
        grid-template-columns: auto;
      }
    }
  }
}
