@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter", sans-serif;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
}

/* Turn of input number arrow*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Custom Antd */
.ant-menu-submenu-title {
  font-weight: bold;
  margin: 0;
}

.ant-menu-submenu-selected {
  color: #1953d8;
}

.ant-menu-submenu-selected > .ant-menu-item-selected {
  background-color: white;
}

.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #2246dc;
  opacity: 0.4;
  border: none;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: " ";
}
