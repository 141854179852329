.wrapper-customer {
  display: flex;
  justify-content: space-between;
  .search-input {
    width: 100%;
    max-width: 500px;
    .custom-input {
      padding: 8px 15px;
    }
  }
  padding: 0 0 20px 0;
}
