.custom-upload-catalog-product-brand {
  .ant-upload-drag {
    padding: 20px;
    margin-top: 5px;
    .ant-upload-text {
      color: #9cadc3 !important;
    }
    .ant-upload-drag-icon {
      path {
        fill: #9cadc3;
      }
    }
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    flex-direction: column;
  }
}
