.toolbar-key-list {
  display: flex;
  gap: 10px;
  row-gap: 12px !important;
  .toolbar-key-list-input {
    width: 560px;
    .ant-input-affix-wrapper {
      border-right-width: 0px;
      ::before {
        content: "";
        position: absolute;
        z-index: 2;
        right: -10px;
        border: 1px solid #9cadc3;
        height: 20px;
      }
    }
    .ant-input-group-addon {
      background-color: transparent;
      .ant-select {
        width: max-content !important;
        .ant-select-selector {
          width: 200px;
          color: #052987;
          border-left-width: 0px;
        }
        .ant-select-arrow {
          color: #052987;
        }
      }
    }
  }
  .toolbar-key-list-actions {
    .toolbar-key-list-actions-space {
      gap: 12px !important;
    }
  }
}
