.custom-content-filter {
  display: flex;
  flex-direction: column;
  .ant-input-affix-wrapper {
    margin-bottom: 8px;
  }
  // ::-webkit-scrollbar {
  //   display: none;
  // }
}
