.custom-table {
  .ant-form-item {
    margin: 0 !important;
  }

  .custom-select-date {
    width: 150px;
    margin: auto;
    gap: 0 !important;
    .warrantyPeriodSupplier {
      .ant-input-number {
        border-radius: 8px 0 0 8px;
      }
    }
    .warrantyUnit {
      .ant-select-selector {
        border-radius: 0 8px 8px 0;
        border-left-width: 0;
      }
    }
    .ant-input-group .ant-input {
      width: 50px !important;
    }
    .ant-input-group .ant-input-group-addon {
      background-color: transparent !important;
      padding: 0 !important;
    }
    .select-after {
      width: 100px !important;
      .ant-form-item {
        margin: 0 !important;
        .ant-form-item-control-input {
          min-height: auto !important;
        }
      }
    }
  }
  // .custom-input-tag {
  //   width: 100%;
  //   .ant-space-item {
  //     &:first-child {
  //       width: 100%;
  //       .hide-input {
  //         .rti--input {
  //           display: none;
  //         }
  //         .rti--container {
  //           border: none;
  //           height: 70px;
  //           overflow: hidden;
  //         }
  //       }
  //     }
  //   }
  // }
  .header-box {
    background: #e9eef5;
    padding: 10px 20px;
    margin: 20px 0;
    border-radius: 8px;
  }
}
.card-container-info {
  display: flex;
  flex-direction: column;
  .ant-card-head {
    background-color: #e7f0ff;
  }
}
