.card-title-detail-seller-supplier {
  background-color: white;
  border: 1.5px dashed #2246dc;
  border-radius: 12px;
  padding: 12px 8px 12px 24px;
  margin: 0px 0px 16px !important;
  display: flex;
  align-items: center;
  .avatar {
    border-radius: 100px;
  }
  .title {
    font-size: 16px;
    font-weight: 600;
    color: #262f3b;
  }
  .status-true {
    background-color: #edf9f0;
    color: #00ab78;
    padding: 8px 14px;
    border-radius: 10px;
  }
  .status-false {
    color: #d2434d;
    background: #feefef;
    border-radius: 10px;
    padding: 8px 14px;
  }
  .button-action {
    height: 38px !important;
    padding: 0 16px !important;
  }
}
