.custom-input {
  // border-radius: 5px;
}

.custom-select {
  // border-radius: 5px;
  min-width: 150px;
}

.custom-button {
  // border-radius: 5px;
  min-width: 120px;
}

.ant-checkbox-inner,
.ant-tree-checkbox-inner {
  border-radius: 2px;
}

.ant-form-item .ant-input-number-handler-wrap,
.ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number {
  width: 100%;
}

.ant-select-tree {
  background-color: #ffffff;
}

.custom-tag-status-product-available {
  background-color: #e7f0ff;
  border: 1px solid #2246dc;
  color: #2246dc;
  padding: 8px 0;
  width: 150px;
  font-size: 14px;
}

.custom-tag-status-product-unavailable {
  background-color: #f3f3f7;
  border: 1px solid #5b6673;
  color: #5b6673;
  padding: 8px 0;
  width: 150px;
  font-size: 14px;
}

.custom-tag-status-product-contact {
  background-color: #fff4ec;
  border: 1px solid #ef9351;
  color: #ef9351;
  padding: 8px 0;
  width: 150px;
  font-size: 14px;
}
