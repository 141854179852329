.list-by-price-bid {
  .filter-box {
    margin-bottom: 10px;
    padding: 5px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    .search-input {
      width: 30%;
    }
  }
  .ant-form-item {
    margin-bottom: 0px;
  }

  .text-hidden-two {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
