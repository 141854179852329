@import "../../styles/mixins";
.custom-table-component {
  position: relative;
  .left-footer {
    position: absolute;
    left: 0;
    bottom: 16px;
  }
  .ant-table-cell-with-append {
    display: flex;
    align-items: center;
  }
  .ant-table-cell {
    // padding: 12px 14px !important;
    .text-center {
      display: flex;
      justify-content: center;
    }
    .ant-table-column-title {
      text-transform: uppercase;
    }
  }
  .highlight {
    &:hover {
      background-color: #dcf4ff;
    }
    background-color: #dcf4ff;
  }
  .ant-table-thead th {
    text-align: center !important;
    vertical-align: middle;
    font-weight: 600 !important;
    color: #5b6673 !important;
    text-transform: uppercase;
  }
}
// ===============================Reponesive====================================
@include mobile_portrait() {
  .custom-table-component {
    .left-footer {
      display: none;
    }
  }
}
