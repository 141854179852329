@import "../../../../../../styles/mixins";

.filter-product-bargain-container {
  justify-content: space-between;
  .search-input {
    width: 40% !important;
    gap: 80px;
    .icon {
      margin-right: 16px;
    }
    .custom-input {
      width: 100%;
    }
    .input-seller {
      height: fit-content;
    }
  }
  .btn-create {
    background-color: #2246dc;
    font-weight: 600;
    color: white;
    font-size: 14px;
  }
}
