.form-add-code-product {
  overflow-y: scroll;
  max-height: 60vh;
  margin-bottom: 16px;
  .card-info-product {
    font-size: 13px;
    .ant-card-head {
      background-color: #e7f0ff;
      color: #2246dc;
      font-weight: 600;
      min-height: 0;
      .ant-card-head-title {
        padding: 10px 0;
      }
    }
    .ant-card-body {
      padding: 16px;
      .ant-form-item {
        margin-bottom: 16px;
      }
    }
    .ant-form-item-label {
      font-weight: 600;
    }
    .ant-form-item-extra {
      color: #ef9351;
      font-size: 12px;
    }

    .info-product {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: "product-code product-name brand" "category warranty uom" "product-size product-size product-weight" "editor editor editor";
      column-gap: 16px;
      .product-code {
        grid-area: product-code;
      }
      .product-name {
        grid-area: product-name;
      }
      .short-code {
        grid-area: short-code;
      }
      .brand {
        grid-area: brand;
      }
      .category {
        grid-area: category;
      }
      .editor {
        grid-area: editor;
      }
      .warranty {
        grid-area: warranty;
      }
      .uom {
        grid-area: uom;
      }
      .product-size {
        grid-area: product-size;
      }
      .product-weight {
        grid-area: product-weight;
      }
    }
  }
}
