.input-tag-container-detail {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .isHidden {
    // max-height: 62px;
    // overflow: hidden;
  }
  .input-tag-input {
    flex: 1;
    width: auto;
  }

  .input-tag-tags {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .custom-tags-by-serial {
    overflow: hidden;
    background-color: #e7f0ff;
    border: 1px solid #2246dc;
    color: #2246dc !important;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3px;
    height: 26px !important;
    color: #000;
    font-size: 14px;
  }
  .custom-tags-unvailable {
    overflow: hidden;
    background-color: #ffe0e2;
    color: #d2434d !important;
    border: 1px solid #d2434d;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3px;
    height: 26px !important;
    color: #000;
    font-size: 14px;
    svg {
      fill: #d2434d !important;
      font-size: 10px;
    }
  }
  .ant-tag-close-icon {
    font-size: 10px;
    color: #2246dc;
    font-weight: 800;
  }
  .input-tag-tag,
  .input-value {
    margin-right: 4px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }
  .input-enter-serial {
    font-size: 14px;
    padding: 0px 8px;
    height: 26px !important;
    background-color: #e7f0ff;
    border: 1px solid #2246dc;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    svg {
      fill: #2246dc;
      font-size: 10px;
    }
  }
}
