.agent-list {
  .search-input {
    width: 100%;
    max-width: 500px;
    margin: 0 0 20px 0;
    .custom-input {
      padding: 8px 15px;
    }
  }
}
