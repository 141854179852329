.form-create-product-brand {
  margin-bottom: 50px;
  .ant-form-item-extra {
    font-size: 11px;
    font-style: italic;
  }
  .form-footer-product-brand {
    position: fixed;
    bottom: 20px;
    width: calc(100% - 40px);
    .ant-card-body {
      display: flex;
      justify-content: end;
      padding: 10px 24px;
      button {
        margin-left: 20px;
        &:nth-child(1) {
          border: 2px solid #2246dc;
          color: #2246dc;
        }
        &:nth-child(2) {
          border: 2px solid #2246dc;
          color: #2246dc;
        }
        &:nth-child(3) {
          font-weight: 600;
          border: 2px solid #2246dc;
          color: #2246dc;
        }
      }
    }
  }
}
