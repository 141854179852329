@import "../../../styles/mixins";

.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  .left-col {
    width: 50%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .right-col {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .form {
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .logo {
        height: 80px;
        width: 80px;
      }

      .greeting {
        font-size: 16px;
      }

      .form-tile {
        font-weight: 600;
        font-size: 22px;
      }

      .form-input {
        width: 400px;
      }

      .button-login-group {
        display: flex;
        justify-content: center;
        padding: 24px 0px;
        .button-login-zitadel {
          background-color: white;
          padding: 12px 28px;
          border-radius: 16px;
          border: 2px solid #e2e8f0;
          color: #1e293b;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
          cursor: pointer;
        }
      }

      .forgot-password {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }

      .submit-btn {
        width: 100%;
        background-color: #1953d8;
        height: 44px;
        border-radius: 7px;
      }
    }
  }
}

// ===============================Reponesive====================================
@include tablet_portrait() {
  .container {
    .left-col {
      display: none;
    }

    .right-col {
      width: 100%;
    }
  }
}

@include mobile_portrait() {
  .container {
    .right-col {
      .form {
        width: 90%;
      }
    }
  }
}
