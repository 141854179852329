.price-info-container {
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  // column-gap: 64px;
  .price-info-row {
    // gap: 64px;
    .vat-value-group {
      flex-wrap: nowrap;
      align-items: end;
    }
  }
  .add-on-after {
    color: #ffffff;
    width: 22px;
  }
  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .vat {
    label {
      width: 100%;
      .label-vat {
        height: 22px;
        display: flex;
        align-content: center;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .ant-form-item {
          margin: 0;
        }
      }
    }
  }
}
