.info-marketing {
  color: #262f3b;
  text-align: justify;
  .custom-row {
    // margin-bottom: 16px;
  }
  .box-gen-html {
    width: 100%;
    border-radius: 16px;
    padding: 12px;
    position: relative;
    .gen-html {
      display: inline-block;
      width: 100%;
      height: 405px;
      overflow: hidden;
      img {
        max-width: 100%;
        border-radius: 16px;
      }
    }
    .show-more {
      position: absolute;
      background-color: #fff;
      opacity: 0.7;
      border-radius: 0 0 16px 16px;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: #2246dc;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
      }
      img {
        max-width: 100%;
        border-radius: 16px;
      }
    }
  }
  .gen-html-modal {
    display: inline-block;
    width: 100%;
    height: 70%;
    overflow: hidden;
    border-radius: 16px;
    img {
      max-width: 100%;
      border-radius: 16px;
    }
  }
}
.gen-html-modal {
  display: inline-block;
  width: 100%;
  height: 70%;
  overflow: hidden;
  margin: 10px 0 20px 0;
  padding: 0 20px;
  border-radius: 16px;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #f8f8f8;
  }
  &::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    border-radius: 20px;
    width: 6px;
  }
  img {
    max-width: 100%;
    border-radius: 16px;
  }
}
