.noti-menu {
  .ant-list-item {
    padding: 5px 0;
  }
}

.ant-menu-root.ant-menu-vertical {
  box-shadow:
    0 3px 6px -4px rgb(0 0 0 / 12%),
    0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  border-radius: 8px;
}
