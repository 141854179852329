.custom-drawer-multi {
  .ant-form-item {
    margin-bottom: 4px;
  }
  .ant-input-number-disabled {
    background-color: transparent;
  }
  .ant-input-disabled,
  .ant-select-selection-item,
  .ant-input-number-disabled .ant-input-number-input,
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: black;
    cursor: default;
  }
  .ant-select-multiple .ant-select-selection-item {
    cursor: default;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before,
  .ant-select-arrow,
  .hideBlock {
    display: none;
  }
  .custom-progress-steps {
    .ant-progress-steps-item {
      width: 30px !important;
      height: 10px !important;
      border: 1px solid #d8dfea;
    }
    .space-progress {
      gap: 0px !important;
      .text-status {
        font-size: 13px;
      }
    }
    .progress-item {
      .ant-progress-steps-item:last-child {
        background: #fc5555;
      }
    }
  }
  .skeleton-detail {
    .ant-skeleton-content {
      .ant-skeleton-title {
        width: 100% !important;
        margin: 0;
        margin-top: 3px;
      }
    }
  }
}
